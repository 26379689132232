const addToDataLayer = (eventName, newData) => {
  const isIOS =
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.firebase;

  if (eventName === 'pageMetaData' && !window.AppInterface && !isIOS) {
    return;
  }

  if (window.AppInterface) {
    window.AppInterface.logEvent(eventName, JSON.stringify(newData));
  } else if (isIOS) {
    const message = {
      command: 'logEvent',
      eventName,
      parameters: newData,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else if (
    window.riDigitalData &&
    window.riDigitalData.add &&
    eventName !== 'pageMetaData'
  ) {
    window.riDigitalData.add(eventName, newData);
  } else {
    console.error(
      `Looks like you haven't initialised window.riDigitalData yet but tried to add data`,
    );
  }
};

export default addToDataLayer;
