import {
  getCurrentActiveFeatureNames,
  isActiveFeatureName,
} from '@paralleldrive/feature-toggles';
import { useEffect, useState } from 'react';

import initialFeatures from '../../lib/features.json';

const activeFeaturesNames = getCurrentActiveFeatureNames({
  initialFeatures,
});

export const useIsGlobaleRedirectActive = () => {
  const [bodyXcountry, setBodyXcountry] = useState(false);

  const isGlobaleRedirectEnabled = isActiveFeatureName(
    'GlobaleRedirect',
    activeFeaturesNames,
  );

  const isGlobaleRedirectOverrideEnabled = isActiveFeatureName(
    'GlobaleRedirectOverride',
    activeFeaturesNames,
  );

  useEffect(() => {
    window.addEventListener('us-xcountry', () => {
      setBodyXcountry(true);
    });
  }, []);

  if (isGlobaleRedirectOverrideEnabled) return true;

  return isGlobaleRedirectEnabled && bodyXcountry;
};
