import addToDataLayer from '../../../helpers/addToDataLayer';
import { productMapper } from '../../../helpers/analyticsMappers';

const checkout = (currency, stepName, stepIndex, lineItems, checkoutType) => {
  const data = {
    ecommerce: {
      checkout: {
        actionField: {
          stepName,
          step: stepIndex,
          action: 'checkout',
          checkoutType,
        },
        products: productMapper(lineItems, 'checkout'),
      },
      currency,
    },
  };

  addToDataLayer('checkout', data);
};

export default checkout;
