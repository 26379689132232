import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import config from '../../config/default.json';
import getMainSiteLocation from '../helpers/getMainSiteLocation';
import handleRedirect from '../helpers/handleRedirect/handleRedirect';
import { cache } from './cache';

const {
  location: { origin, search },
} = window;

const getClientName = () => {
  const queryParams = new URLSearchParams(search);
  if (!queryParams.get('webview')) {
    return 'web-checkout';
  }

  const APP_PREFIX = 'app-checkout';
  return queryParams.get('os')
    ? `${APP_PREFIX}-${queryParams.get('os')}`
    : APP_PREFIX;
};

const aggregatorUriPrefix = getMainSiteLocation(config, origin);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path, extensions: { code } }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Code: ${code}, Path: ${path}, Origin: ${origin}`,
      );
      if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
        handleRedirect(origin);
      }
    });
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}, Status Code: ${networkError.statusCode}`,
    );
    if (networkError.statusCode === 401) {
      handleRedirect(origin);
    }
  }
});

const { aggregatorProxyUri, aggregatorDirectUri, isDevelopment, tokenKey } =
  config;

const isDirectRequest = () => {
  return localStorage.getItem(tokenKey) && isDevelopment;
};

const xCountryEvent = new CustomEvent('us-xcountry');
let isCountryAttributeSet = false;

const getHttpLink = (uri) =>
  createHttpLink({
    uri,

    fetch: (url, options) => {
      return window.fetch(url, options).then((response) => {
        const xCountry = response.headers.get('x-country');

        if (!isCountryAttributeSet && xCountry === 'US') {
          document.body.setAttribute('x-country', xCountry);
          window.dispatchEvent(xCountryEvent);
          isCountryAttributeSet = true;
        }

        return response;
      });
    },
    ...(isDirectRequest() ? null : { credentials: 'include' }),
  });

const withToken = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(tokenKey);

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: isDirectRequest()
    ? errorLink.concat(withToken.concat(getHttpLink(aggregatorDirectUri)))
    : errorLink.concat(
        getHttpLink(aggregatorUriPrefix?.url + aggregatorProxyUri),
      ),
  cache,
  connectToDevTools: isDevelopment,
  name: getClientName(),
  version: '1.0',
});

export default client;
