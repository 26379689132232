import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';

const color = '#f5ebdb';
const MyBagContentLoader = (props) => {
  const { isTablet } = props;
  const dataQa = isTablet ? 'mybag-loader-tablet' : 'mybag-loader-desktop';
  return (
    <ContentLoader
      width="100%"
      height={400}
      backgroundColor={color}
      foregroundColor={color}
      animate={false}
      data-qa={dataQa}
    >
      {isTablet ? (
        <>
          <rect x="44.5%" y="0" rx="3" ry="3" width="93" height="28" />
          <rect x="49.5%" y="36" rx="3" ry="3" width="60" height="17" />
          <rect x="0" y="68" rx="3" ry="0" width="99" height="99" />
          <rect x="54%" y="68" rx="3" ry="3" width="100%" height="17" />
          <rect x="54%" y="88" rx="3" ry="3" width="25%" height="17" />
          <rect x="54%" y="118" rx="3" ry="3" width="18%" height="17" />
          <rect x="54%" y="148" rx="3" ry="3" width="18%" height="17" />
          <rect x="75%" y="148" rx="3" ry="3" width="18%" height="17" />
          <rect x="0" y="198" rx="3" ry="0" width="176" height="17" />
          <rect x="0" y="238" rx="3" ry="0" width="67" height="20" />
          <rect x="66%" y="238" rx="3" ry="0" width="70" height="20" />
          <rect x="0" y="278" rx="3" ry="0" width="49" height="20" />
          <rect x="57%" y="278" rx="3" ry="0" width="84" height="20" />
        </>
      ) : (
        <>
          <rect x="44.5%" y="0" rx="3" ry="3" width="93" height="28" />
          <rect x="49.5%" y="36" rx="3" ry="3" width="60" height="17" />
          <rect x="0" y="68" rx="3" ry="0" width="99" height="99" />
          <rect x="40%" y="68" rx="3" ry="3" width="100%" height="17" />
          <rect x="40%" y="88" rx="3" ry="3" width="25%" height="17" />
          <rect x="40%" y="118" rx="3" ry="3" width="18%" height="17" />
          <rect x="40%" y="148" rx="3" ry="3" width="18%" height="17" />
          <rect x="61%" y="148" rx="3" ry="3" width="18%" height="17" />
          <rect x="0" y="198" rx="3" ry="0" width="176" height="17" />
          <rect x="0" y="238" rx="3" ry="0" width="67" height="20" />
          <rect x="70%" y="238" rx="3" ry="0" width="70" height="20" />
          <rect x="0" y="278" rx="3" ry="0" width="49" height="20" />
          <rect x="66%" y="278" rx="3" ry="0" width="84" height="20" />
        </>
      )}
    </ContentLoader>
  );
};

MyBagContentLoader.propTypes = {
  isTablet: PropTypes.bool,
};

MyBagContentLoader.defaultProps = {
  isTablet: false,
};

export default MyBagContentLoader;
