import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { CartPricePropType } from '../../helpers/prop-types';
import ProductImage from './ProductImage';
import style from './ProductRow.scss';

const cx = classNames.bind(style);

const LineItem = ({
  className,
  productImage,
  imageBaseURL,
  name,
  quantity,
  size,
  wasTotal,
  total,
}) => (
  <li className={cx('mybag__product', className)} data-qa="product">
    <ProductImage
      name={name}
      productImage={productImage}
      baseUrl={imageBaseURL}
    />
    <div className={cx('mybag__product--details')}>
      <h3 className={cx('mybag__product--name')} data-qa="product-name">
        {name}
      </h3>
      <span className={cx('mybag__product--price')} data-qa="product-price">
        {wasTotal ? (
          <>
            <span
              className={cx('mybag__product--price--discounted')}
              data-qa="new-product-price"
            >
              {total.formattedValue}
            </span>
            <span
              className={cx('mybag__product--price--original')}
              data-qa="original-product-price"
            >
              {wasTotal.formattedValue}
            </span>
          </>
        ) : (
          <>{total.formattedValue}</>
        )}
      </span>
      <div className={cx('mybag__product--more')}>
        <span className={cx('mybag__product--qty')} data-qa="product-quantity">
          Qty: <b>{quantity}</b>
        </span>
        <span className={cx('mybag__product--size')} data-qa="product-size">
          Size: <b>{size}</b>
        </span>
      </div>
    </div>
  </li>
);

LineItem.propTypes = {
  className: PropTypes.string,
  total: CartPricePropType.isRequired,
  wasTotal: CartPricePropType,
  productImage: PropTypes.shape(),
  imageBaseURL: PropTypes.string,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

LineItem.defaultProps = {
  className: '',
  productImage: null,
  imageBaseURL: 'images.riverisland.com',
  wasTotal: null,
};

export default LineItem;
