import addToDataLayer from '../../../helpers/addToDataLayer';

const deliveryOption = (name) => {
  const data = {
    deliveryOption: {
      name,
    },
  };

  addToDataLayer('deliveryOption', data);
};

export default deliveryOption;
