import { Button } from '@ri-digital/web-component-library/src/atom/Button';
import LoadingSpinner from '@ri-digital/web-component-library/src/atom/LoadingSpinner';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { ChildrenPropTypes } from '../../helpers/prop-types';
import style from './MainButton.scss';

const cx = classNames.bind(style);

const MainButton = ({
  title,
  dataTestId,
  type,
  variant,
  onClick,
  children,
  loading,
  ...rest
}) => (
  <div className={cx('button-wrapper', variant)} data-qa="main-button">
    <Button
      title={title}
      className={cx('checkout-button', loading && 'checkout-button--loading')}
      qaTag={dataTestId}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {!loading && (children ?? 'Next step')}
      {loading && <LoadingSpinner cssModifier="inButton" />}
    </Button>
  </div>
);

MainButton.propTypes = {
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: ChildrenPropTypes,
  loading: PropTypes.bool,
};

MainButton.defaultProps = {
  type: 'button',
  variant: '',
  onClick: () => null,
  children: undefined,
  loading: false,
};

export default MainButton;
