import React from 'react';

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    data-qa="close-icon"
  >
    <path
      d="M4.53319 3.96008L3.98807 4.5052L9.16673 9.68386L3.98804 14.8625L4.53316 15.4077L9.71185 10.229L14.8905 15.4076L15.4356 14.8625L10.257 9.68386L15.4356 4.50526L14.8904 3.96014L9.71185 9.13874L4.53319 3.96008Z"
      fill="#1D1D1B"
    />
  </svg>
);

export default Close;
