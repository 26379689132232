import React from 'react';

export function IconConfirmedAlt2() {
  return (
    <svg style={{ width: 26, height: 26 }} viewBox="0 0 26 26">
      <path
        fill="currentColor"
        d="M10.14506,20.16064H10.14115a1.49837,1.49837,0,0,1-1.0625-.44531l-5.61133-5.6709a1.49986,1.49986,0,1,1,2.13281-2.10937l4.55078,4.59863L20.4058,6.27881A1.49984,1.49984,0,1,1,22.5269,8.3999L11.20561,19.72119A1.49976,1.49976,0,0,1,10.14506,20.16064Z"
      />
    </svg>
  );
}
