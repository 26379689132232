import React from 'react';

const Warning = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#clip0_7_56)">
        <path
          d="M8.44121 5.42751H7.70177V8.8864H8.44121V5.42751Z"
          fill="#3D3935"
        />
        <path
          d="M8.08396 10.0107C7.9776 10.0107 7.87362 10.0422 7.78519 10.1013C7.69675 10.1604 7.62782 10.2444 7.58712 10.3427C7.54641 10.441 7.53577 10.5491 7.55652 10.6534C7.57727 10.7577 7.62848 10.8535 7.70369 10.9288C7.7789 11.004 7.87473 11.0552 7.97904 11.0759C8.08336 11.0967 8.19149 11.086 8.28976 11.0453C8.38802 11.0046 8.47201 10.9357 8.53111 10.8473C8.5902 10.7588 8.62174 10.6548 8.62174 10.5485C8.62174 10.4059 8.56508 10.2691 8.46423 10.1682C8.36337 10.0674 8.22659 10.0107 8.08396 10.0107Z"
          fill="#3D3935"
        />
        <path
          d="M7.99996 15.3333C6.54957 15.3333 5.13174 14.9032 3.92578 14.0974C2.71982 13.2916 1.77989 12.1463 1.22485 10.8063C0.669803 9.46635 0.524579 7.99186 0.807537 6.56933C1.0905 5.1468 1.78893 3.84013 2.81451 2.81454C3.8401 1.78896 5.14677 1.09053 6.5693 0.807568C7.99183 0.524609 9.46632 0.669834 10.8063 1.22488C12.1463 1.77992 13.2916 2.71985 14.0974 3.92581C14.9032 5.13177 15.3333 6.5496 15.3333 7.99999C15.3333 9.94491 14.5607 11.8102 13.1854 13.1854C11.8101 14.5607 9.94488 15.3333 7.99996 15.3333ZM7.99996 1.27777C6.67043 1.27777 5.37076 1.67202 4.2653 2.41067C3.15983 3.14932 2.29823 4.19918 1.78944 5.42751C1.28065 6.65583 1.14753 8.00745 1.40691 9.31143C1.66628 10.6154 2.30651 11.8132 3.24663 12.7533C4.18675 13.6934 5.38454 14.3337 6.68852 14.593C7.9925 14.8524 9.34412 14.7193 10.5724 14.2105C11.8008 13.7017 12.8506 12.8401 13.5893 11.7347C14.3279 10.6292 14.7222 9.32952 14.7222 7.99999C14.7222 6.21715 14.014 4.50732 12.7533 3.24666C11.4926 1.986 9.78281 1.27777 7.99996 1.27777Z"
          fill="#3D3935"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_56">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Warning;
