/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import LoadingOverlay from '@ri-digital/web-component-library/src/molecule/LoadingOverlay';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import analytics from '../../analytics';
import { CartPricePropType } from '../../helpers/prop-types';
import { useGlobalE } from '../../hooks/useGlobalE';
import { useUserProperties } from '../../hooks/useUserProperties';

const GlobalE = ({
  analyticsData: { stepName, stepIndex, stepDisplayName },
  cart,
}) => {
  const [searchParams] = useSearchParams();
  const tokenQueryParam = searchParams.get('token');
  const {
    loading,
    cartToken,
    SDKBasePath,
    globalEClientSettings,
    confirmGlobalEOrder,
  } = useGlobalE(tokenQueryParam);

  const { userProperties } = useUserProperties();

  const token = tokenQueryParam ?? cartToken;

  const {
    locale: { currency },
  } = cart;

  useEffect(() => {
    if (token && SDKBasePath && globalEClientSettings) {
      (function (w, d, u, t, o, h, m, s, l) {
        w.globaleObject = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (w[o].m = m), (w[o].v = h);
        s = d.createElement(t);

        s.async = true;
        s.src = `${u}?v=${h}`;
        l = d.getElementsByTagName(t)[0];

        if (l) {
          l.parentNode.insertBefore(s, l);
        } else {
          document.body.appendChild(s);
        }
      })(
        window,
        document,
        `${SDKBasePath}Scripts/Merchants/globale.merchant.client.js`,
        'script',
        'gle',
        '2.1.4',
        '451',
      );

      window.gle('ScriptsURL', SDKBasePath);
      window.gle('Checkout', token, 'gle-iframe');
      window.gle('SetMerchantParameters', globalEClientSettings);

      analytics.pageMetaData('checkout-globale', stepDisplayName);
      analytics.checkout(
        currency,
        stepName,
        stepIndex,
        cart?.lineItems,
        'local-checkout',
      );

      window.gle('OnCheckoutStepLoaded', (data) => {
        switch (data.StepId) {
          case data.Steps.LOADED:
          case data.Steps.CONFIRMATION:
            if (data.IsSuccess) {
              confirmGlobalEOrder();
              analytics.pageMetaData('checkout-globale', 'confirmation');
              analytics.purchaseGlobale({
                currency,
                actionData: data,
                lineItems: cart?.lineItems,
                hashedEmail: userProperties.hashedEmail,
              });
            }
            break;
          default:
            break;
        }
      });
    }
  }, [token, SDKBasePath, globalEClientSettings]);

  if (loading) {
    return <LoadingOverlay loading variant="inOverlay" />;
  }

  return <div id="gle-iframe" data-qa="global-e-iframe" />;
};

GlobalE.propTypes = {
  analyticsData: PropTypes.shape({
    stepIndex: PropTypes.number,
    stepName: PropTypes.string,
    stepDisplayName: PropTypes.string,
  }).isRequired,
  cart: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    discountCodes: PropTypes.arrayOf(PropTypes.string),
    discountList: PropTypes.arrayOf(PropTypes.shape()),
    subtotal: CartPricePropType.isRequired,
    total: CartPricePropType.isRequired,
    remaining: CartPricePropType,
    totalLineItems: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.shape()),
    paymentMethod: PropTypes.string,
    shippingAddress: PropTypes.shape(),
    billingAddress: PropTypes.shape(),
    deliveryOption: PropTypes.shape(),
    locale: PropTypes.shape({
      country: PropTypes.string,
      currency: PropTypes.string,
    }),
  }).isRequired,
};

export default GlobalE;
