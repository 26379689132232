import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import analytics from '../../analytics';
import MyBagContainer from '../../containers/MyBagContainer';
import { CartPricePropType } from '../../helpers/prop-types';
import { useIsGlobaleRedirectActive } from '../../hooks/useIsGlobaleRedirectActive';
import { GET_CURRENT_ROUTE } from '../../lib/queries';
import Container from '../Container';
import RedirectBanner from '../RedirectBanner';
import Stepper from '../Stepper';
import style from './Layout.scss';

const cx = classNames.bind(style);

const Layout = ({ cart, loading, error }) => {
  const isGlobalRedirectActive = useIsGlobaleRedirectActive();
  const {
    data: { currentRoute },
  } = useQuery(GET_CURRENT_ROUTE);

  const { stepIndex, stepName, stepDisplayName } = currentRoute;

  const {
    locale: { currency },
  } = cart;

  useEffect(() => {
    if (!stepName || !stepIndex) {
      return;
    }

    analytics.pageMetaData('checkout', stepDisplayName);

    if (!error) {
      analytics.checkout(
        currency,
        stepName,
        stepIndex,
        cart?.lineItems,
        'regular',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  return (
    <main className={cx('container')} data-qa="layout">
      <Container>
        <Stepper />
        {isGlobalRedirectActive && (
          <div
            className={cx('redirect-banner-wrapper')}
            data-qa="redirect-banner-desktop"
          >
            <RedirectBanner />
          </div>
        )}
        <div className={cx('page')}>
          <div className={cx('content')} data-qa="content">
            <Outlet />
          </div>

          <MyBagContainer cart={cart} loading={loading} error={error} />
        </div>
      </Container>
    </main>
  );
};
export default Layout;

Layout.defaultProps = {
  loading: false,
  error: undefined,
};

Layout.propTypes = {
  cart: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    discountCodes: PropTypes.arrayOf(PropTypes.string),
    discountList: PropTypes.arrayOf(PropTypes.shape()),
    subtotal: CartPricePropType.isRequired,
    total: CartPricePropType.isRequired,
    remaining: CartPricePropType,
    totalLineItems: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.shape()),
    paymentMethod: PropTypes.string,
    shippingAddress: PropTypes.shape(),
    billingAddress: PropTypes.shape(),
    deliveryOption: PropTypes.shape(),
    locale: PropTypes.shape({
      country: PropTypes.string,
      currency: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};
