import addToDataLayer from '../../../helpers/addToDataLayer';
import {
  mapGlobalEOrder,
  productMapper,
} from '../../../helpers/analyticsMappers';

const purchaseGlobale = ({ currency, actionData, lineItems, hashedEmail }) => {
  const data = {
    ecommerce: {
      purchase: {
        actionField: {
          ...mapGlobalEOrder(actionData),
          action: 'purchase',
          checkoutType: 'local-checkout',
          tax: '',
        },
        products: productMapper(lineItems),
      },
      currencyCode: currency,
      hashedEmail,
    },
  };

  addToDataLayer('purchase', data);
};

export default purchaseGlobale;
