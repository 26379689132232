import addToDataLayer from '../../../helpers/addToDataLayer';
import { deliveryProvider } from '../../../lib/reactives';

const deliveryLocation = (deliveryMethod) => {
  const data = {
    event: 'deliveryLocation',
    delivery: {
      deliveryMethod,
      deliveryLocation:
        deliveryMethod === 'home' ? 'personal_address' : deliveryProvider(),
    },
  };

  addToDataLayer('deliveryLocation', data);
};

export default deliveryLocation;
