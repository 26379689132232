import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { GET_SELECTED_DELIVERY_METHOD } from '../../lib/queries';
import { Routes } from '../../routes/routes';
import { IconConfirmedAlt2 } from '../Icons/IconConfirmedAlt2';
import style from './Stepper.scss';

const cx = classNames.bind(style);

export function Stepper() {
  const { pathname } = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const getActiveGroup = () => {
    const allRoutes = Object.keys(Routes);

    for (let i = 0; i < allRoutes.length; i += 1) {
      const currentKey = allRoutes[i];
      const currentRoute = Routes[currentKey];

      const isMatch = matchPath(
        {
          path: currentRoute.path,
        },
        pathname,
      );

      if (isMatch) {
        setActiveStepIndex(currentRoute.stepperGroupIndex);
        return;
      }
    }

    setActiveStepIndex(0);
  };

  useEffect(() => {
    getActiveGroup();
  }, [pathname]);

  const {
    data: { selectedDeliveryMethod },
  } = useQuery(GET_SELECTED_DELIVERY_METHOD);

  const getDynamicAddressStep = () => {
    switch (selectedDeliveryMethod) {
      case 'home':
        return Routes.homeDelivery;
      case 'store':
        return Routes.storeSearch;
      case 'shop':
        return Routes.localShopSearch;
      default:
        return Routes.homeDelivery;
    }
  };

  const stepGroupList = [
    [Routes.deliveryType],
    [getDynamicAddressStep()],
    [Routes.deliveryOptions],
    [Routes.reviewAndPay],
  ];

  return (
    <ol className={cx('stepper')} data-qa="stepper">
      {stepGroupList.map((stepGroup, index) => {
        const isCompleted = index < activeStepIndex;
        const isActive = activeStepIndex === index;
        const step = stepGroup[0];

        return (
          <li
            key={step.path}
            className={cx('step', {
              active: isActive,
              completed: isCompleted,
            })}
            data-qa={`step-${index}`}
          >
            <p className={cx('step_number')}>
              <span>{isCompleted ? <IconConfirmedAlt2 /> : index + 1}</span>
            </p>
            {isCompleted ? (
              <Link
                to={step.path}
                className={cx('step_label')}
                data-qa={`step-${index}-link`}
              >
                {step.label}
              </Link>
            ) : (
              <p className={cx('step_label')} data-qa={`step-${index}-label`}>
                {step.label}
              </p>
            )}
          </li>
        );
      })}
    </ol>
  );
}
