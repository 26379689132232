import React from 'react';
import { Route } from 'react-router-dom';

import { useActiveFeature } from '../../hooks/useActiveFeature';
import { ReviewAndPayPage, ReviewAndPayPageV2 } from '../../pages';
import { Routes } from '../../routes/routes';
import RoutesWrapper from '../../routes/RoutesWrapper';

const ReviewAndPayRedirectLayout = () => {
  const isPaymentV2Active = useActiveFeature('PaymentElementsV2');

  const ReviewPageComponent = isPaymentV2Active
    ? ReviewAndPayPageV2
    : ReviewAndPayPage;

  return (
    <RoutesWrapper>
      <Route
        path={Routes.reviewAndPay.path}
        element={
          <ReviewPageComponent analytics={Routes.reviewAndPay.analytics} />
        }
      />
    </RoutesWrapper>
  );
};

export default ReviewAndPayRedirectLayout;
