import { useQuery } from '@apollo/client';

import { GET_CART_AND_COUNTRY_CONFIG } from '../../lib/queries';
import errorMapper from '../utils/errorMapper';

export const useInitApp = () => {
  const { data, loading, error } = useQuery(GET_CART_AND_COUNTRY_CONFIG, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  return {
    cart: data && data.cart,
    deliveryCountriesConfig: data && data.deliveryCountriesConfig,
    loading,
    error: errorMapper(error),
  };
};
