import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@ri-digital/web-component-library/src/molecule/Input';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

import analytics from '../../analytics';
import validateUser from '../../helpers/validations/validateUser';
import { ieShop } from '../../lib/reactives';
import { TRANSLATIONS as text } from '../../translations';
import ConfirmOwnership from '../ConfirmOwnership';
import MainButton from '../MainButton';
import style from './CollectionDetails.scss';

const cx = classNames.bind(style);

const CollectionDetails = ({
  onSubmit,
  isLocalShop,
  hasPhoneNumber,
  setShippingAddressLoading,
  country,
}) => {
  const INITIAL_STATE = {
    hasPhone: hasPhoneNumber,
  };
  const deliveryMethod = isLocalShop ? 'shop' : 'store';
  const countryMessage = country === 'GB' ? 'GB' : 'Ireland';
  const { ShowLocalShopForWebIE } = ieShop();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_STATE,
    resolver: yupResolver(validateUser('global', 'collectFrom')),
    mode: 'all',
  });

  return (
    <div className={cx('collection-details')} data-qa="collection-details">
      <h3
        className={cx('collection-details__heading')}
        data-qa="collection-details-heading"
      >
        {text.collectionDetails}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} data-qa="collection-details-form">
        <p className={cx('collection-details__input-label')}>
          {text.firstName}
        </p>
        <Input
          id="first-name"
          error={errors.firstName?.message}
          errorType="first-name"
          dataTestId="first-name"
          autoComplete="given-name"
          type="text"
          data-notranslate
          maxLength="30"
          variant={cx('collection-details__input')}
          {...register('firstName')}
        />
        <p className={cx('collection-details__input-label')}>{text.lastName}</p>
        <Input
          id="last-name"
          error={errors.lastName?.message}
          errorType="last-name"
          dataTestId="last-name"
          autoComplete="family-name"
          type="text"
          data-notranslate
          maxLength="30"
          variant={cx('collection-details__input')}
          {...register('lastName')}
        />
        {hasPhoneNumber && (
          <>
            <p className={cx('collection-details__input-label')}>
              {text.mobileNumber}
            </p>
            {ShowLocalShopForWebIE ? (
              <Input
                data-qa="collection-details-phone-number-global"
                id="phone"
                error={errors.phone?.message}
                maxLength={19}
                errorType="phone"
                dataTestId="phone"
                autoComplete="phone"
                type="text"
                data-notranslate
                variant={cx('collection-details__input')}
                placeholder={`Please include country code if outside ${countryMessage}`}
                {...register('phone')}
              />
            ) : (
              <div className={cx('collection-details__phone-number')}>
                <span
                  className={cx('collection-details__phone-number-prepend')}
                  data-qa="collection-details-phone-number-prepend"
                >
                  {text.phonePrefix}
                </span>
                <Input
                  id="phone"
                  error={errors.phone?.message}
                  maxLength={11}
                  errorType="phone"
                  dataTestId="phone"
                  autoComplete="phone"
                  type="text"
                  data-notranslate
                  variant={cx('collection-details__phone-number-text')}
                  {...register('phone')}
                />
              </div>
            )}
          </>
        )}
        {isLocalShop && <ConfirmOwnership />}
        <MainButton
          loading={setShippingAddressLoading}
          disabled={setShippingAddressLoading}
          title="next-step"
          dataTestId="next-step-button"
          type="submit"
          variant="black"
          onClick={() => analytics.deliveryLocation(deliveryMethod)}
        />
      </form>
    </div>
  );
};

CollectionDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLocalShop: PropTypes.bool,
  hasPhoneNumber: PropTypes.bool,
  setShippingAddressLoading: PropTypes.bool,
  country: PropTypes.string,
};

CollectionDetails.defaultProps = {
  isLocalShop: false,
  hasPhoneNumber: false,
  setShippingAddressLoading: false,
  country: 'GB',
};

export default CollectionDetails;
