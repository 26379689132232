import PropTypes from 'prop-types';

export const ChildrenPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
  PropTypes.string,
  PropTypes.number,
]);

export const CartPricePropType = PropTypes.shape({
  formattedValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  currencyCode: PropTypes.string,
});

export const CartPropType = PropTypes.shape({
  email: PropTypes.string,
  discountCodes: PropTypes.arrayOf(PropTypes.string),
  discountList: PropTypes.arrayOf(PropTypes.shape()),
  subtotal: CartPricePropType.isRequired,
  // TODO: can be required when CHK-982 is done
  remaining: CartPricePropType,
  totalLineItems: PropTypes.number.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape()),
  shippingAddress: PropTypes.shape(),
  billingAddress: PropTypes.shape(),
  deliveryOption: PropTypes.shape(),
  giftcards: PropTypes.arrayOf(PropTypes.shape()),
  giftcardTotal: CartPricePropType,
});

export const OrderPropType = PropTypes.shape({
  discountCodes: PropTypes.arrayOf(PropTypes.string),
  discountList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: CartPricePropType,
    }),
  ),
  subtotal: CartPricePropType.isRequired,
  total: CartPricePropType.isRequired,
  remaining: CartPricePropType.isRequired,
  totalLineItems: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape()),
  shippingAddress: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
  }),
  billingAddress: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
  }),
  giftcardTotal: CartPricePropType.isRequired,
  deliveryOption: PropTypes.shape(),
  email: PropTypes.string,
  createdAt: PropTypes.string,
});
