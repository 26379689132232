import addPageMetaDataTracking from '@ri-digital/web-data-layer/dist/events/AddPageMetaDataTracking';

import config from '../../../../config/default.json';
import addToDataLayer from '../../../helpers/addToDataLayer';
import getMainSiteLocation from '../../../helpers/getMainSiteLocation';
import { userProps } from '../../../lib/reactives';

const getShippingPassStatus = (shippingPass) => {
  if (!shippingPass) return 'not-eligible';

  const { product, active } = shippingPass;
  if (!product) return 'not-eligible';
  if (active) return 'active';

  return 'eligible';
};

export const getShippingPassStatusConfirmationPage = (
  deliveryPassInfo,
  hasDeliveryPass,
) => {
  if (!deliveryPassInfo || Object.keys(deliveryPassInfo).length === 0) {
    return 'not-eligible';
  }
  if (deliveryPassInfo.customerHasActiveSubscription || hasDeliveryPass) {
    return 'active';
  }
  if (deliveryPassInfo.customerEligible) {
    return 'eligible';
  }

  return 'not-eligible';
};

const pageMetaData = (
  type = 'checkout',
  name = 'checkout',
  deliveryPassInfo,
  hasDeliveryPass,
) => {
  const { href, origin } = window.location;

  const isLoggedIn = () => !!userProps();
  const { userId, userAge, totalOrders, shippingPass, country, currency } =
    userProps();

  const envMapped = getMainSiteLocation(config, origin);

  if (!userId) return;

  const data = {
    name: `checkout.${name}`,
    contentGroup: 'checkout',
    type,
    countryCode: country,
    currencyCode: currency,
    checkoutAppVersion: 'v2',
    isUserLoggedIn: isLoggedIn(),
    userId,
    userAge: userAge && userAge?.toString(),
    userHasTransacted: !!(totalOrders && totalOrders > 0),
    shippingPassStatus:
      name === 'confirmation'
        ? getShippingPassStatusConfirmationPage(
            deliveryPassInfo,
            hasDeliveryPass,
          )
        : getShippingPassStatus(shippingPass),
    envMap: envMapped?.env,
  };

  const formattedData = addPageMetaDataTracking(data);

  addToDataLayer('pageMetaData', formattedData);

  if (window.newrelic) {
    // eslint-disable-next-line no-undef
    const interaction = newrelic.interaction();
    interaction.setName(href);
    interaction.save();
  }
};

export default pageMetaData;
