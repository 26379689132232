import LoadingOverlay from '@ri-digital/web-component-library/src/molecule/LoadingOverlay';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import { CartPricePropType } from '../../helpers/prop-types';
import { useUSRedirect } from '../../hooks/useUSRedirect';
import LocalCheckoutPage from '../../pages/LocalCheckoutPage';
import { Routes } from '../routes';

export default function GlobalE({ cart }) {
  const { newCart, loading } = useUSRedirect({ cart });

  if (loading) {
    return <LoadingOverlay loading variant="inOverlay" />;
  }

  return (
    <RouterRoutes>
      <Route
        path="*"
        element={<Navigate replace to={Routes.localCheckout.path} />}
      />
      <Route
        path={Routes.localCheckout.path}
        element={
          <LocalCheckoutPage
            cart={newCart}
            analytics={Routes.localCheckout.analytics}
          />
        }
      />
    </RouterRoutes>
  );
}

GlobalE.propTypes = {
  cart: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    discountCodes: PropTypes.arrayOf(PropTypes.string),
    discountList: PropTypes.arrayOf(PropTypes.shape()),
    subtotal: CartPricePropType.isRequired,
    total: CartPricePropType.isRequired,
    remaining: CartPricePropType,
    totalLineItems: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.shape()),
    paymentMethod: PropTypes.string,
    shippingAddress: PropTypes.shape(),
    billingAddress: PropTypes.shape(),
    deliveryOption: PropTypes.shape(),
  }).isRequired,
};
