import { useEffect } from 'react';

import { Routes } from '../../routes/routes';

const excludedRoutes = [
  Routes.reviewAndPay.path,
  Routes.orderConfirmation.path,
];

export const useRedirectToCart = (lineItems, isGlobalECheckout) => {
  useEffect(() => {
    if (
      excludedRoutes.includes(window.location.pathname) ||
      isGlobalECheckout
    ) {
      return;
    }

    if (lineItems?.length === 0) {
      window.location.href = '/cart/';
    }
  }, [lineItems, isGlobalECheckout]);
};
