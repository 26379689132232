import PropTypes from 'prop-types';
import React from 'react';

import MyBag from '../../components/MyBag';
import { CartPropType } from '../../helpers/prop-types';

const MyBagContainer = ({ cart, loading, error }) => {
  return loading || (cart && Object.keys(cart).length > 0) ? (
    <MyBag
      loading={loading}
      error={error}
      cart={cart}
      isDesktop
      items={[]}
      totals={[]}
    />
  ) : null;
};

export default MyBagContainer;

MyBagContainer.defaultProps = {
  loading: false,
  error: undefined,
};

MyBagContainer.propTypes = {
  cart: CartPropType.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};
