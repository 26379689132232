import { Button } from '@ri-digital/web-component-library/src/atom/Button';
import classNames from 'classnames/bind';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { redirectToGlobalE } from '../../lib/reactives';
import { Routes } from '../../routes/routes';
import { TRANSLATIONS as text } from '../../translations';
import Warning from '../Icons/Warning';
import styles from './RedirectBanner.scss';

const cx = classNames.bind(styles);

export default function RedirectBanner() {
  const navigate = useNavigate();

  return (
    <div className={cx('redirect-banner')} data-qa="redirect-banner">
      <Warning />
      <p>
        {text.redirectBanner.part1} <strong>{text.redirectBanner.part2}</strong>{' '}
        {text.redirectBanner.part3}{' '}
        <Button
          onClick={() => {
            redirectToGlobalE(true);
            navigate(Routes.localCheckout.path);
          }}
          title="link"
          className={cx('redirect-banner-link')}
          qaTag="us-modal-button"
        >
          {text.redirectBanner.link}
        </Button>
      </p>
    </div>
  );
}

RedirectBanner.propTypes = {};
