import DefaultImage from '@ri-digital/web-component-library/src/atom/DefaultImage';
import { TransformedImage } from '@ri-digital/web-component-library/src/atom/TransformedImage/TransformedImage';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import style from './ProductRow.scss';

const cx = classNames.bind(style);

const ProductImage = ({ name, baseUrl, productImage }) => (
  <>
    {productImage ? (
      <TransformedImage
        baseUrl={baseUrl}
        assetId={productImage.id}
        transformation="CheckoutThumbnail"
        className={cx('mybag__product--image')}
        alt={name}
        dataTestId="product-image"
      />
    ) : (
      <DefaultImage
        className={cx('mybag__product--image')}
        data-qa="default-image"
      />
    )}
  </>
);

ProductImage.propTypes = {
  productImage: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
};

ProductImage.defaultProps = {
  productImage: null,
  baseUrl: 'images.riverisland.com',
};

export default ProductImage;
