import { gql } from '@apollo/client';

import { CART_FIELDS_FRAGMENT, ORDER_FIELDS_FRAGMENT } from '../fragments';

export const GET_DELIVERY_METHODS = gql`
  query DeliveryMethods($country: String!) {
    deliveryMethods(args: { country: $country }) {
      key
    }
  }
`;

export const GET_CART = gql`
  ${CART_FIELDS_FRAGMENT}
  query Cart($cartLocation: CartLocation) {
    cartLocation @client @export(as: "cartLocation")
    cart(args: { cartLocation: $cartLocation }) {
      ...AllCartFields
    }
  }
`;

export const GET_CONVERT_CART = gql`
  ${CART_FIELDS_FRAGMENT}
  query Cart($args: GetCartInput!) {
    cart(args: $args) {
      ...AllCartFields
    }
  }
`;

export const GET_CURRENT_ROUTE = gql`
  query getCurrentRoute {
    currentRoute @client
  }
`;

export const GET_SELECTED_DELIVERY_METHOD = gql`
  query getSelectedDeliveryMethod {
    selectedDeliveryMethod @client
  }
`;

export const FIND_STORES = gql`
  query FindStores($args: FindStoresInput!) {
    findStores(args: $args) {
      clickAndCollect
      distanceFromCustomerInMiles
      name
      id
      openingTimesHtml
      location {
        latitude
        longitude
      }
      address {
        city
        country
        addressLine1
        addressLine2
        addressLine3
        postalCode
        state
      }
      phoneNumber
    }
  }
`;

export const GET_SAVED_ADDRESSES = gql`
  query SavedAddresses($args: GetSavedAddressesInput) {
    savedAddresses(args: $args) {
      canSaveAddress
      addresses {
        id
        addressType
        title
        firstName
        lastName
        addressLine1
        addressLine2
        addressLine3
        city
        postalCode
        country
        phone
        deliveryInstruction
        isBfpo
        isDefaultAddress
        isShippable
        isBillable
      }
    }
  }
`;

export const DELIVERY_COUNTRY_CONFIGURATION = gql`
  query DeliveryCountriesConfig {
    deliveryCountriesConfig {
      countryCode
      deliveryEnabled
      billingEnabled
      localShopEnabled
      countryName
      vatMessageEnabled
    }
  }
`;

export const FIND_SHOPS = gql`
  query FindShops($args: FindShopsInput!) {
    findShops(args: $args) {
      name
      address {
        city
        country
        addressLine1
        addressLine2
        addressLine3
        postalCode
        state
      }
      location {
        latitude
        longitude
      }
      distanceFromCustomerInMiles
      carrierCode
      logoUrl
      openingTimes {
        dayOfWeek
        periods {
          from
          to
        }
      }
      deliveryOptions {
        deliveryCountry
        serviceLevel
        deliveryMethod
        expressReplacement
        price {
          value
          formattedValue
          currencyCode
        }
        chooseDeliveryDetails
        deliveryDetails {
          key
          date
          timeSlots {
            key
            startTime
            endTime
            greenSlot
            reserved
          }
        }
      }
    }
  }
`;

export const GET_DELIVERY_OPTIONS = gql`
  query DeliveryOptions {
    deliveryOptions {
      deliveryCountry
      serviceLevel
      deliveryMethod
      expressReplacement
      price {
        formattedValue
      }
      chooseDeliveryDetails
      deliveryDetails {
        key
        date
        timeSlots {
          key
          startTime
          endTime
          greenSlot
          reserved
        }
        disabled
      }
    }
  }
`;

export const GET_ORDER_STATUS = gql`
  query OrderStatus($args: OrderStatusInput!) {
    orderStatus(args: $args) {
      status
      id
    }
  }
`;

export const GET_ORDER = gql`
  ${ORDER_FIELDS_FRAGMENT}
  query Order($args: OrderInput!) {
    order(args: $args) {
      ...OrderFields
    }
  }
`;

export const GET_CART_AND_COUNTRY_CONFIG = gql`
  ${CART_FIELDS_FRAGMENT}
  query InitApp($cartLocation: CartLocation) {
    cartLocation @client @export(as: "cartLocation")
    cart(args: { cartLocation: $cartLocation }) {
      ...AllCartFields
    }
    deliveryCountriesConfig {
      countryCode
      deliveryEnabled
      billingEnabled
      localShopEnabled
      countryName
      vatMessageEnabled
    }
  }
`;

export const GET_SAVED_CARDS = gql`
  query SavedCards($hideExpired: Boolean, $regionFilter: RegionFilter) {
    savedCards(
      args: { hideExpired: $hideExpired, regionFilter: $regionFilter }
    ) {
      paymentId
      brand
      expirationDate
      lastFourDigits
      nameOnCard
    }
  }
`;

export const USER_PROPERTIES = gql`
  query UserProperties($includeDeliveryPassInfo: Boolean) {
    userProperties(
      args: { includeDeliveryPassInfo: $includeDeliveryPassInfo }
    ) {
      userId
      tenure
      totalOrders
      userAge
      hashedEmail
      riCustomerId
      deliveryPassInfo {
        customerEligible
        customerHasActiveSubscription
        deliveryPassExpiryDate
      }
    }
  }
`;

export const FEATURE_FLAGS_CONFIG = gql`
  query FeaturesConfig($country: String!) {
    featuresConfig(args: { country: $country }) {
      name
      enabled
    }
  }
`;

export const GET_PAYMENT_CONFIG = gql`
  query paymentConfig($args: PaymentConfigInput!) {
    paymentConfig(args: $args) {
      wallets {
        apple
        google
      }
    }
  }
`;

export const GET_GLOBALE_SETTINGS = gql`
  query ClientSettings {
    getGlobaleConfig {
      clientSettings {
        AllowClientTracking {
          Value
        }
        CDNEnabled {
          Value
        }
        CheckoutContainerSuffix {
          Value
        }
        FullClientTracking {
          Value
        }
        IsMonitoringMerchant {
          Value
        }
        IsV2Checkout {
          Value
        }
        SetGEInCheckoutContainer {
          Value
        }
        ShowFreeShippingBanner {
          Value
        }
        TabletAsMobile {
          Value
        }
        AdScaleClientSDKURL {
          Value
        }
        AmazonUICulture {
          Value
        }
        ChargeMerchantForPrepaidRMAOfReplacement {
          Value
        }
        CheckoutCDNURL {
          Value
        }
        EnableReplaceUnsupportedCharactersInCheckout {
          Value
        }
        GTM_ID {
          Value
        }
        RefundRMAReplacementShippingTypes {
          Value
        }
        RefundRMAReplacementStatuses {
          Value
        }
        MerchantIdHashed {
          Value
        }
      }
    }
  }
`;
