import addToDataLayer from '../../../helpers/addToDataLayer';
import { productMapper } from '../../../helpers/analyticsMappers';
import { deliveryProvider } from '../../../lib/reactives';

const shippingInfo = (cart) => {
  const {
    deliveryOption: { price, serviceLevel },
    shippingAddress: { addressType },
    locale: { currency },
  } = cart;

  const deliveryLocation =
    addressType === 'home' ? 'personal_address' : deliveryProvider();

  const data = {
    event: 'shippingInfo',
    ecommerce: {
      shipping: {
        actionField: {
          currencyCode: price.currencyCode,
          shipping: price.value,
          shippingMethod: serviceLevel,
          deliveryMethod: addressType,
          deliveryLocation,
          checkoutType: 'regular',
        },
        products: productMapper(cart.lineItems),
      },
    },
    currencyCode: currency,
  };

  addToDataLayer('shippingInfo', data);
};

export default shippingInfo;
