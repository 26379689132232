import addToDataLayer from '../../../helpers/addToDataLayer';
import { mapOrder, productMapper } from '../../../helpers/analyticsMappers';

const purchase = ({
  currency,
  order,
  hashedEmail,
  riCustomerId,
  customerHasActiveSubscription,
  billing,
}) => {
  const data = {
    ecommerce: {
      purchase: {
        actionField: {
          ...mapOrder(order, currency, customerHasActiveSubscription),
          action: 'purchase',
          checkoutType: 'regular',
          tax: '',
          riCustomerId,
          ...billing,
        },
        products: productMapper(order.lineItems),
      },
      currencyCode: currency,
      hashedEmail,
    },
  };

  addToDataLayer('purchase', data);
};

export default purchase;
