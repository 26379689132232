import LoadingOverlay from '@ri-digital/web-component-library/src/molecule/LoadingOverlay';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import USRedirectModal from '../../components/USRedirectModal/USRedirectModal';
import { isGlobaleRedirectActive } from '../../helpers/isGlobaleRedirectActive/isGlobaleRedirectActive';
import { useFeaturesConfig } from '../../hooks/useFeaturesConfig';
import { useInitApp } from '../../hooks/useInitApp';
import { useRedirectToCart } from '../../hooks/useRedirectToCart';
import useUniversalVariable from '../../hooks/useUniversalVariable';
import { useUserProperties } from '../../hooks/useUserProperties';
import {
  featuresConfig as featuresConfigVar,
  redirectToGlobalE,
  userProps,
} from '../../lib/reactives';
import GlobalERoute from '../../routes/GlobalE';
import MainRoutes from '../../routes/MainRoutes';
import { Routes } from '../../routes/routes';

const MainLayout = () => {
  const { pathname } = window.location;
  const [searchParams] = useSearchParams();

  const {
    userProperties,
    loading: userPropertiesLoading,
    error: userPropertiesError,
  } = useUserProperties();

  const { cart, loading: cartLoading, error: cartError } = useInitApp();

  const { loading: featuresConfigLoading, error: featuresConfigError } =
    useFeaturesConfig(cart?.locale?.country, {
      skip: !cart?.locale?.country,
      onCompleted: ({ featuresConfig }) => featuresConfigVar(featuresConfig),
    });

  const isLoading =
    cartLoading || featuresConfigLoading || userPropertiesLoading;
  const isError = cartError || featuresConfigError || userPropertiesError;
  const isGlobalECheckout =
    Routes.localCheckout.path === pathname && searchParams.get('token');

  const redirectGlobalE = redirectToGlobalE();

  const isRedirectActive = isGlobaleRedirectActive();

  useRedirectToCart(cart?.lineItems, isGlobalECheckout);

  useEffect(() => {
    if (userProperties && cart) {
      userProps({
        ...userProperties,
        ...cart?.locale,
        shippingPass: cart?.shippingPass,
      });
    }
  }, [userProperties, cart]);

  useUniversalVariable(cart, userProperties);

  if (isLoading) {
    return <LoadingOverlay loading variant="inOverlay" />;
  }

  const getCheckoutContent = () => {
    if (
      isGlobalECheckout ||
      cart.checkoutFlow === 'globale' ||
      redirectGlobalE
    ) {
      return (
        <GlobalERoute analytics={Routes.localCheckout.analytics} cart={cart} />
      );
    }
    return (
      <>
        {isRedirectActive && <USRedirectModal />}
        <MainRoutes cart={cart} error={isError} loading={isLoading} />
      </>
    );
  };

  return cart?.lineItems.length > 0 || isGlobalECheckout ? (
    getCheckoutContent()
  ) : (
    <LoadingOverlay loading variant="inOverlay" />
  );
};

export default MainLayout;
