import { TRANSLATIONS as text } from '../../translations';

const baseErrorMessage = {
  Unknown: text.errors.generic,
};

const errorMapper = (error, errorMessages) => {
  if (!error) {
    return null;
  }

  if (error.networkError) {
    return text.errors.generic;
  }

  const errorFromCode = error.graphQLErrors.reduce((acc, err) => {
    if (!errorMessages) {
      return null;
    }
    if (errorMessages[err.extensions.errorCode]) {
      return errorMessages[err.extensions.errorCode];
    }
    return null;
  }, null);

  if (errorFromCode) {
    return errorFromCode;
  }
  return baseErrorMessage.Unknown;
};

export default errorMapper;
