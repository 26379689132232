import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import PromoCode from '@ri-digital/web-component-library/src/organism/PromoCode';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import getCookie from '../../../analytics/helpers/getCookie/getCookie';
import analytics from '../../analytics';
import promoCodeFromValidationSchema from '../../helpers/validations/validatePromoCode';
import { useAddDiscountCode } from '../../hooks/useAddDiscountCode';
import { GET_DELIVERY_OPTIONS } from '../../lib/queries';
import { isUpdateBackendCartLoadingVar as setIsUpdateBackendCartLoadingVar } from '../../lib/reactives';
import { TRANSLATIONS as text } from '../../translations';

const { promoCodeText } = text;

const PromoCodeContainer = () => {
  const {
    handleSubmit,
    register,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      promoCode: '',
    },
    resolver: yupResolver(promoCodeFromValidationSchema),
    mode: 'onSubmit',
  });

  const [discountCode, setDiscountCode] = useState(null);

  const isOnDeliveryPage =
    window.location.pathname?.includes('delivery-option');

  const {
    addDiscountCode,
    data,
    error: addDiscountCodeError,
    location,
    loading,
  } = useAddDiscountCode({
    onCompleted: () => {
      resetForm();
      analytics.addDiscountCode({ discountCode, isValid: true });
    },
    onError: () => {
      resetForm();
      analytics.addDiscountCode({ discountCode, isValid: false });
    },
    ...(isOnDeliveryPage && {
      refetchQueries: [{ query: GET_DELIVERY_OPTIONS }],
    }),
  });

  const isUpdateBackendCartLoading = useReactiveVar(
    setIsUpdateBackendCartLoadingVar,
  );

  const openPromoCode = () => {
    const promoCodeCookie = getCookie('qb_promo_code_entry');
    if (promoCodeCookie === 'true') {
      document.querySelector("[data-qa='promo-code-cta']").click();
    }
  };

  useEffect(() => {
    setIsUpdateBackendCartLoadingVar(loading);
  }, [loading]);

  useEffect(() => {
    openPromoCode();
    if (!isEmpty(errors)) {
      resetForm();
    }
  }, [location]);

  const handleSubmitPromo = (form) => {
    const { promoCode } = form;
    setDiscountCode(promoCode);
    addDiscountCode({
      code: promoCode,
    });
  };

  return (
    <>
      <PromoCode
        buttonText={promoCodeText.togglePrompt}
        labelText={promoCodeText.inputLabel}
        applyText={promoCodeText.CTA}
        onSubmit={handleSubmit(handleSubmitPromo)}
        confirmationText={promoCodeText.confirmation}
        showConfirmation={!!data}
        error={errors?.promoCode?.message || addDiscountCodeError}
        location={location.pathname}
        disabled={isUpdateBackendCartLoading}
        data-notranslate
        {...register('promoCode')}
      />
    </>
  );
};

export default PromoCodeContainer;
