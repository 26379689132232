import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import style from './FlashMessage.scss';

const cx = classNames.bind(style);

const FlashMessage = ({ message }) => {
  return (
    <div className={cx('flash-message__wrapper')}>
      <div className={cx('flash-message')} data-qa="flash-message">
        <p className={cx('flash-message__copy')}>{message}</p>
      </div>
    </div>
  );
};

FlashMessage.propTypes = {
  message: PropTypes.string,
};

FlashMessage.defaultProps = {
  message: '',
};

export default FlashMessage;
