import * as yup from 'yup';

import { TRANSLATIONS as text } from '../../translations';
import validateGBPhone from './validateGBPhone';
import validatePhone from './validatePhone';

const { addressForm } = text;
const { formErrors, formValidations } = addressForm;
const allowedChars = RegExp(/^([a-zA-Z -]+)$/);

const phoneValidations = {
  global: validatePhone,
  GB: validateGBPhone,
};

const validateName = {
  homeAddress: formValidations.charsNotAllowed,
  collectFrom: formValidations.nameInvalidChars,
};

const validateUser = (phone = 'global', name = 'homeAddress') => {
  return yup
    .object()
    .shape({
      firstName: yup
        .string()
        .trim()
        .required(formErrors.firstName)
        .matches(allowedChars, validateName[name]),

      lastName: yup
        .string()
        .trim()
        .required(formErrors.lastName)
        .matches(allowedChars, validateName[name]),
    })
    .concat(phoneValidations[phone]);
};

export default validateUser;
