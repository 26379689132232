import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import GlobalE from '../../containers/GlobalE';
import { CartPricePropType } from '../../helpers/prop-types';
import style from './LocalCheckoutPage.scss';

const cx = classNames.bind(style);

export default function LocalCheckoutPage({ analytics, cart }) {
  return (
    <div className={cx('local-checkout')} data-qa="local-checkout">
      <GlobalE analyticsData={analytics} cart={cart} />
    </div>
  );
}

LocalCheckoutPage.propTypes = {
  analytics: PropTypes.shape({
    stepIndex: PropTypes.number,
    stepName: PropTypes.string,
  }).isRequired,
  cart: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    discountCodes: PropTypes.arrayOf(PropTypes.string),
    discountList: PropTypes.arrayOf(PropTypes.shape()),
    subtotal: CartPricePropType.isRequired,
    total: CartPricePropType.isRequired,
    remaining: CartPricePropType,
    totalLineItems: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.shape()),
    paymentMethod: PropTypes.string,
    shippingAddress: PropTypes.shape(),
    billingAddress: PropTypes.shape(),
    deliveryOption: PropTypes.shape(),
  }).isRequired,
};
