import config from '../../../config/default.json';

export default function injectQubitScript() {
  if (document.getElementById('qubitScript')) return;
  const { qubitScript } = config;
  const script = document.createElement('script');
  script.async = true;
  script.id = 'qubitScript';
  script.src = qubitScript;
  document.head.appendChild(script);
}
