import { Button } from '@ri-digital/web-component-library/src/atom/Button';
import classNames from 'classnames/bind';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { redirectToGlobalE } from '../../lib/reactives';
import { Routes } from '../../routes/routes';
import { TRANSLATIONS as text } from '../../translations';
import Warning from '../Icons/Warning';
import Modal from '../Modal';
import styles from './USRedirectModal.scss';

const cx = classNames.bind(styles);
const { redirectModal } = text;

export default function USRedirectModal() {
  const navigate = useNavigate();

  return (
    <Modal eventName="openUsModal">
      <div className={cx('us-popup')} data-qa="us-modal">
        <Warning />
        <p className={cx('us-popup__header')} data-qa="us-modal-header">
          {redirectModal.header.part1}
          <strong> {redirectModal.header.part2}</strong>
        </p>
        <p className={cx('us-popup__text')} data-qa="us-modal-text">
          {redirectModal.text.part1}
          <strong>{redirectModal.text.part2}</strong>
          {redirectModal.text.part3}
        </p>
        <Button
          onClick={() => {
            redirectToGlobalE(true);
            navigate(Routes.localCheckout.path);
          }}
          title="link"
          className={cx('us-popup__btn')}
          qaTag="us-modal-button"
        >
          {redirectModal.link}
        </Button>
      </div>
    </Modal>
  );
}
