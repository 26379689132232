import addToDataLayer from '../../../helpers/addToDataLayer';

const deliveryPassCartInteraction = ({
  action,
  currency,
  id,
  name,
  price,
  quantity,
}) => {
  const eventName =
    action === 'add' ? 'deliveryPassAddToCart' : 'deliveryPassRemoveFromCart';

  const data = {
    ecommerce: {
      currencyCode: currency,
    },
  };

  data.ecommerce[action] = {
    products: [
      {
        id,
        name,
        price,
        quantity,
        categories: ['digital'],
        size: 'One Size (UK)',
        division: 'digital',
        subcategory: 'digital',
        brands: ['river_island'],
      },
    ],
  };

  addToDataLayer(eventName, data);
};

export default deliveryPassCartInteraction;
