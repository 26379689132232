import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { FIND_STORES } from '../../lib/queries';

const useFindStore = ({ searchQuery, storeId }) => {
  const { data, ...rest } = useQuery(FIND_STORES, {
    variables: {
      args: {
        searchQuery,
      },
    },
  });

  const findStoreFromData = useMemo(() => {
    if (!data) {
      return {};
    }
    return data.findStores.reduce((acc, store, index) => {
      if (store.id !== storeId) {
        return acc;
      }
      return {
        ...store,
        markerLabel: (index + 1).toString(),
      };
    }, {});
  }, [data, storeId]);

  return {
    ...rest,
    data: findStoreFromData,
  };
};

export default useFindStore;
