import { lazy } from 'react';

export const AddressFormPage = lazy(() => import('./AddressFormPage'));
export const AddressListPage = lazy(() => import('./AddressListPage'));
export const BFPOFormPage = lazy(() => import('./BFPOFormPage'));
export const DeliveryMethodPage = lazy(() => import('./DeliveryMethodPage'));
export const DeliveryOptionsPage = lazy(() => import('./DeliveryOptionsPage'));
export const HomeDeliveryPage = lazy(() => import('./HomeDeliveryPage'));
export const LocalShopConfirmationPage = lazy(() =>
  import('./LocalShopConfirmationPage'),
);
export const LocalShopDetailsPage = lazy(() =>
  import('./LocalShopDetailsPage'),
);
export const LocalShopSearchPage = lazy(() => import('./LocalShopSearchPage'));
export const OrderConfirmationPage = lazy(() =>
  import('./OrderConfirmationPage'),
);
export const ReviewAndPayPage = lazy(() => import('./ReviewAndPayPage'));
export const ReviewAndPayPageV2 = lazy(() => import('./ReviewAndPayPageV2'));
export const StoreConfirmationPage = lazy(() =>
  import('./StoreConfirmationPage'),
);
export const StoreDetailsPage = lazy(() => import('./StoreDetailsPage'));
export const StoreSearchPage = lazy(() => import('./StoreSearchPage'));
export const NotAvailablePage = lazy(() => import('./NotAvailablePage'));
export const LocalCheckoutPage = lazy(() => import('./LocalCheckoutPage'));
export const StoreErrorPage = lazy(() => import('./StoreErrorPage'));
