import { InMemoryCache } from '@apollo/client';
import { merge } from 'lodash';

import {
  cartLocation,
  currentRoute,
  deliveryProvider,
  journeyHasError,
  selectedDeliveryMethod,
  userProps,
} from './reactives';

export const cache = new InMemoryCache({
  typePolicies: {
    Cart: {
      fields: {
        lineItems: {
          merge(existing = [], incoming) {
            if (incoming.length < existing.length) {
              return incoming;
            }

            return merge(incoming, existing);
          },
        },
        giftcards: {
          merge(existing = [], incoming) {
            if (incoming && incoming.length < existing.length) {
              return incoming;
            }
            return merge(incoming, existing);
          },
        },
      },
    },

    Query: {
      fields: {
        cartLocation: {
          read() {
            return cartLocation();
          },
        },
        selectedDeliveryMethod: {
          read() {
            return selectedDeliveryMethod();
          },
        },
        currentRoute: {
          read() {
            return currentRoute();
          },
        },
        journeyHasError: {
          read() {
            return journeyHasError();
          },
        },
        userProps: {
          read() {
            return userProps();
          },
        },
        deliveryProvider: {
          read() {
            return deliveryProvider();
          },
        },
      },
    },
  },
});
