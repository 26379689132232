import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { CONFIRM_GLOBALE_ORDER, INITIALISE_GLOBALE } from '../../lib/mutations';
import { GET_GLOBALE_SETTINGS } from '../../lib/queries';
import errorMapper from '../utils/errorMapper';

export const useGlobalE = (hasToken) => {
  const {
    data: globaleSettings,
    loading: globaleESettingsLoading,
    error: globaleESettingsError,
  } = useQuery(GET_GLOBALE_SETTINGS);

  const [initialiseGlobale, { loading, called, error, data }] =
    useMutation(INITIALISE_GLOBALE);

  const [confirmGlobalEOrder] = useMutation(CONFIRM_GLOBALE_ORDER);

  useEffect(() => {
    if (hasToken) {
      return;
    }

    initialiseGlobale();
  }, [hasToken, initialiseGlobale]);

  if (hasToken) {
    return {
      loading: globaleESettingsLoading,
      SDKBasePath:
        globaleSettings?.getGlobaleConfig?.clientSettings?.CheckoutCDNURL
          ?.Value,
      globalEClientSettings: globaleSettings?.getGlobaleConfig?.clientSettings,
      error: errorMapper(globaleESettingsError),
      confirmGlobalEOrder,
    };
  }

  return {
    called,
    loading,
    cartToken: data?.initialiseGlobale?.id,
    SDKBasePath: data?.initialiseGlobale.clientSettings?.CheckoutCDNURL?.Value,
    globalEClientSettings: data?.initialiseGlobale?.clientSettings,
    error: errorMapper(error),
    confirmGlobalEOrder,
  };
};
