import { useReactiveVar } from '@apollo/client';
import {
  getCurrentActiveFeatureNames,
  isActiveFeatureName,
} from '@paralleldrive/feature-toggles';

import initialFeatures from '../../lib/features.json';
import { featuresConfig as featuresConfigVar } from '../../lib/reactives';

export const useActiveFeature = (feature) => {
  const { search } = window.location;

  const featuresConfig = useReactiveVar(featuresConfigVar);
  const activeFeatures = getCurrentActiveFeatureNames({
    initialFeatures,
    req: { query: search },
  });

  return (
    isActiveFeatureName(feature, activeFeatures) ||
    featuresConfig.some(({ name, enabled }) => name === feature && enabled)
  );
};
