import * as fragments from '../../lib/fragments';

export const replaceCartCache = (key) => (cache, res) => {
  cache.modify({
    fields: {
      cart: () =>
        cache.writeFragment({
          data: res.data[key],
          fragment: fragments.CART_FIELDS_FRAGMENT,
        }),
    },
  });
};
