import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { formatWord } from '../../helpers/formatWord/formatWord';
import style from './Address.scss';

const cx = classNames.bind(style);

const Address = ({
  addressType,
  title,
  firstName,
  lastName,
  phone,
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  postalCode,
  dataTestId,
  country,
  isBfpo,
}) => (
  <div data-qa={dataTestId}>
    {addressType === 'home' && isBfpo ? (
      <ul
        className={cx('address-description-list')}
        data-qa="bfpo-address"
        data-notranslate
      >
        <li
          className={cx('address-description-list__item')}
          data-qa="bfpo-service-rank-name"
        >
          {firstName}
        </li>
        <li
          className={cx('address-description-list__item')}
          data-qa="bfpo-unit-regiment"
        >
          {addressLine1}
        </li>
        <li
          className={cx('address-description-list__item')}
          data-qa="bfpo-operation"
        >
          {addressLine2 && `${addressLine2}`}
        </li>
        <li
          className={cx('address-description-list__item')}
          data-qa="bfpo-number"
        >
          {postalCode}
        </li>
      </ul>
    ) : (
      <div data-qa="standard-address" data-notranslate>
        <ul
          className={cx('address-description-list')}
          data-qa="address-description-list"
        >
          <li
            className={cx('address-description-list__item')}
            data-qa="address-title-name"
            data-cs-mask
          >
            {formatWord(title, '.')} {firstName} {lastName}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-line1"
            data-cs-mask
          >
            {addressLine1 && `${addressLine1}`}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-line2"
            data-cs-mask
          >
            {addressLine2 && `${addressLine2}`}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-line3"
            data-cs-mask
          >
            {addressLine3 && `${addressLine3}`}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-city"
            data-cs-mask
          >
            {city}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-zip-postal-code"
            data-cs-mask
          >
            {postalCode}
          </li>
          <li
            className={cx('address-description-list__item')}
            data-qa="address-country"
            data-cs-mask
          >
            {country}
          </li>
          {phone && (
            <li
              className={cx('address-description-list__item')}
              data-qa="address-telephone-number"
              data-cs-mask
            >
              <span>Tel: {phone}</span>
            </li>
          )}
        </ul>
      </div>
    )}
  </div>
);

Address.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  phone: PropTypes.string,
  dataTestId: PropTypes.string,
  addressType: PropTypes.string,
  isBfpo: PropTypes.bool,
};

Address.defaultProps = {
  firstName: '',
  lastName: '',
  title: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  postalCode: '',
  country: '',
  phone: '',
  dataTestId: 'address',
  addressType: '',
  isBfpo: false,
};

export default Address;
