const formatBrands = (brands) => {
  if (brands?.length) {
    return brands.map((brand) => {
      return brand.replaceAll(' ', '_').toLowerCase();
    });
  }

  return ['unknown'];
};

export default formatBrands;
