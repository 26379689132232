import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import useCountryConfig from '../../hooks/useCountryConfig';
import { TRANSLATIONS as text } from '../../translations';
import style from './VatMessage.scss';

const cx = classNames.bind(style);

const VatMessage = ({ country }) => {
  const { deliveryCountriesConfig } = useCountryConfig({
    countryCode: country,
  });

  if (!deliveryCountriesConfig?.vatMessageEnabled) {
    return null;
  }

  return (
    <div className={cx('vat-message')} data-qa="vat-message">
      <p className={cx('vat-message__text')}>{text.vatMessage.content}</p>
    </div>
  );
};

VatMessage.propTypes = {
  country: PropTypes.string.isRequired,
};

export default VatMessage;
