import { TRANSLATIONS as text } from '../../translations';

const { deliveryOptions } = text;

const getDeliveryServiceLevel = ({ serviceLevel, shippingAddress } = {}) => {
  const { addressType, country, isBfpo } = shippingAddress ?? {};

  if (
    serviceLevel === 'express' &&
    (['shop', 'store'].includes(addressType) || country !== 'GB' || isBfpo)
  ) {
    return deliveryOptions.type.express;
  }

  if (serviceLevel === 'express' && country === 'GB') {
    return deliveryOptions.type.nextDay;
  }

  return deliveryOptions.type[serviceLevel] || '';
};

export default getDeliveryServiceLevel;
