import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import CollectionDetails from '../../components/CollectionDetails';
import OpeningTimes from '../../components/OpeningTimes';
import StoreDetail from '../../components/StoreDetail';
import useFindStore from '../../hooks/useFindStore';
import { useSetShippingAddress } from '../../hooks/useSetShippingAddress';
import { currentRoute } from '../../lib/reactives';
import { Routes } from '../../routes/routes';
import { TRANSLATIONS as text } from '../../translations';
import style from './StoreConfirmationPage.scss';

const cx = classNames.bind(style);

export default function StoreConfirmationPage({ analytics }) {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('searchQuery');

  const {
    setShippingAddress,
    called,
    error: setShippingAddressError,
    loading: setShippingAddressLoading,
  } = useSetShippingAddress();

  const { data } = useFindStore({ searchQuery, storeId });

  useEffect(() => {
    currentRoute({ ...analytics });
  }, []);

  useEffect(() => {
    if (called && !setShippingAddressLoading && !setShippingAddressError) {
      navigate(Routes.deliveryOptions.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, setShippingAddressLoading]);

  if (isEmpty(data)) {
    return null;
  }

  const {
    address: { state, __typename, ...addressRest },
    name,
    phoneNumber,
    openingTimesHtml,
    id,
  } = data;

  const handleSubmit = ({ hasPhone: _hasPhone, ...formDataRest }) => {
    setShippingAddress({
      address: {
        ...formDataRest,
        ...addressRest,
        phone: phoneNumber,
        addressType: 'store',
      },
      shippingSameAsBilling: false,
      collectionDetails: {
        ...formDataRest,
      },
      storeDetails: {
        storeId: id,
      },
    });
  };

  return (
    <div className={cx('store-confirmation')}>
      <StoreDetail
        name={name}
        address={{
          ...addressRest,
          postalCode: addressRest.postalCode,
          phone: phoneNumber,
        }}
      />
      <div
        className={cx('store-confirmation__opening')}
        data-qa="store-confirmation-opening"
      >
        <h3
          className={cx('store-confirmation__heading')}
          data-qa="store-confirmation-heading"
        >
          {text.storeDetails.openingTimes}
        </h3>
        <OpeningTimes openingTimesHtml={openingTimesHtml} />
      </div>
      <CollectionDetails
        onSubmit={handleSubmit}
        setShippingAddressLoading={setShippingAddressLoading}
      />
      {setShippingAddressError && <p>{setShippingAddressError}</p>}
    </div>
  );
}

StoreConfirmationPage.propTypes = {
  analytics: PropTypes.shape({
    stepIndex: PropTypes.number,
    stepName: PropTypes.string,
  }).isRequired,
};
