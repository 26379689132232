import Totals from '@ri-digital/web-component-library/src/organism/Totals';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { UNLIMITED_DELIVERY_ID } from '../../constants';
import PromoCodeContainer from '../../containers/PromoCodeContainer';
import getDeliveryServiceLevel from '../../helpers/getDeliveryServiceLevel';
import { CartPropType } from '../../helpers/prop-types';
import { TRANSLATIONS as text } from '../../translations';
import ProductRow from '../ProductRow';
import UnderlinedCTA from '../UnderlinedCTA';
import VatMessage from '../VatMessage';
import style from './MyBag.scss';
import { MyBagSkeleton } from './MyBagSkeleton';

const cx = classNames.bind(style);
const { mybag } = text;

const MyBag = ({ cart, loading, error, isDesktop }) => {
  if (loading) {
    return <MyBagSkeleton />;
  }

  if (error && typeof error === 'object') {
    return `Something went wrong... ${error.message}`;
  }

  if (error && typeof error === 'string') {
    return `${error}`;
  }

  return (
    <aside
      className={cx(
        'mybag-wrapper',
        isDesktop ? 'mybag-wrapper--desktop' : 'mybag-wrapper--mobile',
      )}
      data-qa="mybag-wrapper"
    >
      <div className={cx('mybag')}>
        <div className={cx('mybag__header')} data-qa="mybag-header">
          <h2 data-qa="mybag-counter">
            {mybag.header}
            {` (${cart.totalQuantity})`}
          </h2>
          <div className={cx('mybag__header__anchor')}>
            <UnderlinedCTA as="a" href="/cart" data-qa="mybag-cart-link">
              {mybag.CTA}
            </UnderlinedCTA>
          </div>
        </div>
        <div className={cx('mybag__productslist')} data-qa="mybag-products">
          <ul className={cx('mybag__products')}>
            {cart.lineItems.map((lineItem, index) => (
              <ProductRow
                {...lineItem}
                key={`${lineItem.productId}${index + 1}`}
                isUnlimitedDelivery={
                  lineItem.productId === UNLIMITED_DELIVERY_ID
                }
              />
            ))}
          </ul>
        </div>
        <div
          className={cx(
            'mybag__promo',
            isDesktop ? 'mybag__promo--desktop' : 'mybag__promo--mobile',
          )}
          data-qa="mybag-promo"
        >
          <PromoCodeContainer />
        </div>
        <div className={cx('mybag__totals')} data-qa="mybag-totals">
          <Totals
            subtotal={cart.subtotal?.formattedValue}
            promotions={cart.discountList}
            delivery={cart?.deliveryOption}
            remaining={cart?.remaining?.formattedValue}
            giftcard={
              Number(cart?.giftcardTotal?.value)
                ? cart?.giftcardTotal?.formattedValue
                : ''
            }
            serviceLevel={getDeliveryServiceLevel({
              shippingAddress: cart?.shippingAddress,
              serviceLevel: cart?.deliveryOption?.serviceLevel,
            })}
            isIncludingDelivery
          />
        </div>
      </div>
      <VatMessage country={cart?.locale?.country} />
    </aside>
  );
};

MyBag.propTypes = {
  cart: CartPropType,
  loading: PropTypes.bool,
  isDesktop: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};

MyBag.defaultProps = {
  cart: undefined,
  isDesktop: false,
  loading: false,
  error: undefined,
};

export default MyBag;
