import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import config from '../config/default.json';
import App from './App';
import injectQubitScript from './helpers/injectQubitScript';
import client from './lib/apolloClient';
import queryClient from './lib/queryClient';

const { isDevelopment, gtmEnvironment, gtmAuth, gtmId, dataLayerName } = config;

const tagManagerArgs = {
  gtmId,
  dataLayerName,
  ...(gtmEnvironment && { preview: gtmEnvironment }),
  ...(gtmAuth && { auth: gtmAuth }),
};

TagManager.initialize(tagManagerArgs);

injectQubitScript();

render(
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {isDevelopment && <ReactQueryDevtools />}
    </QueryClientProvider>
  </ApolloProvider>,

  // eslint-disable-next-line no-undef
  document.getElementById('checkout'),
);
