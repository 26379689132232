import { useQuery } from '@apollo/client';

import { FEATURE_FLAGS_CONFIG } from '../../lib/queries';
import errorMapper from '../utils/errorMapper';

export const useFeaturesConfig = (country, options) => {
  const { loading, error, data } = useQuery(FEATURE_FLAGS_CONFIG, {
    variables: {
      country,
    },
    ...options,
  });

  return {
    features: data?.featuresConfig,
    loading,
    error: errorMapper(error),
  };
};
