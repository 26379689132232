import * as yup from 'yup';

import { TRANSLATIONS as text } from '../../translations';

const { addressForm } = text;
const { formErrors, formValidations } = addressForm;

const phoneRegex = RegExp(/^(\+|\d)(\d ?){1,19}$/, 'i');

export default yup.object().shape({
  hasPhone: yup.boolean(),
  phone: yup.string().when('hasPhone', {
    is: true,
    then: yup
      .string()
      .required(formErrors.phone)
      .matches(phoneRegex, formValidations.phone),
  }),
});
