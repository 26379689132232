import addToDataLayer from '../../../helpers/addToDataLayer';

const addDiscountCode = ({ discountCode, isValid }) => {
  const data = {
    discountCode: {
      code: discountCode,
      isValid,
    },
  };

  addToDataLayer('addDiscountCode', data);
};

export default addDiscountCode;
