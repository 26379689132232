import { useEffect } from 'react';

import extractDomain from '../../helpers/extractDomain';

const useSDKSync = () => {
  useEffect(() => {
    document.cookie = `shouldSDKSync=true;domain=${extractDomain()};path=/`;
  }, []);
};

export default useSDKSync;
