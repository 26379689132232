import { useQuery } from '@apollo/client';

import * as queries from '../../lib/queries';
import errorMapper from '../utils/errorMapper';

export const useUserProperties = (includeDeliveryPassInfo = false) => {
  const { data, loading, error } = useQuery(queries.USER_PROPERTIES, {
    variables: {
      includeDeliveryPassInfo,
    },
  });

  return {
    userProperties: data?.userProperties,
    loading,
    error: errorMapper(error),
  };
};
