import addToDataLayer from '../../../helpers/addToDataLayer';

const paymentMethod = (type, name) => {
  const data = {
    paymentMethod: {
      type,
      name,
    },
  };

  addToDataLayer('paymentMethod', data);
};

export default paymentMethod;
