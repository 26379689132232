import addToDataLayer from '../../../helpers/addToDataLayer';
import { productMapper } from '../../../helpers/analyticsMappers';
import { deliveryProvider } from '../../../lib/reactives';

const addPaymentInfo = (type, name, cart) => {
  const {
    deliveryOption: { price, serviceLevel },
    shippingAddress: { addressType },
    locale: { currency },
  } = cart;

  const deliveryLocation =
    addressType === 'home' ? 'personal_address' : deliveryProvider();

  const data = {
    event: 'addPaymentInfo',
    ecommerce: {
      payment: {
        actionField: {
          paymentMethod: name,
          currencyCode: price.currencyCode,
          shipping: price.value,
          shippingMethod: serviceLevel,
          deliveryMethod: addressType,
          deliveryLocation,
          checkoutType: 'regular',
        },
        products: productMapper(cart.lineItems),
      },
    },
    currencyCode: currency,
    payment: {
      type,
      name,
    },
  };

  addToDataLayer('addPaymentInfo', data);
};

export default addPaymentInfo;
