const DEFAULT_LOCATION = 'https://www.riverisland.com/';

const getMainSiteLocation = (origins, link) => {
  const { mainSiteUrl } = origins;
  const found = mainSiteUrl.find((item) => link.includes(item.env));
  if (!found) {
    return { url: DEFAULT_LOCATION };
  }
  return found;
};

export default getMainSiteLocation;
