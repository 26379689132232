import config from '../../../config/default.json';
import getMainSiteLocation from '../getMainSiteLocation';

const REDIRECT_PATH = 'start-checkout';
const REDIRECT_SEARCH_QUERY = '?returnUrl=login-callback';

const handleRedirect = (url) => {
  const origin = getMainSiteLocation(config, url);
  window.location.href = `${origin?.url}${REDIRECT_PATH}${REDIRECT_SEARCH_QUERY}`;
};

export default handleRedirect;
