import AppHeader from '@ri-digital/web-component-library/src/organism/AppHeader';
import React from 'react';

import config from '../../../config/default.json';
import getMainSiteLocation from '../../helpers/getMainSiteLocation';

const Header = () => {
  const {
    location: { origin },
  } = window;
  const site = getMainSiteLocation(config, origin);

  return <AppHeader mainSiteUrl={site?.url} />;
};

export default Header;
