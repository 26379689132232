import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { DELIVERY_COUNTRY_CONFIGURATION } from '../../lib/queries';
import errorMapper from '../utils/errorMapper';

const useCountryConfig = (args) => {
  const { data, loading, error } = useQuery(DELIVERY_COUNTRY_CONFIGURATION);

  const countryData = useMemo(() => {
    if (args?.countryCode) {
      return data?.deliveryCountriesConfig.find(
        (config) => config.countryCode === args?.countryCode,
      );
    }
    return data?.deliveryCountriesConfig;
  }, [args?.countryCode, data]);

  return {
    deliveryCountriesConfig: countryData,
    loading,
    error: errorMapper(error),
  };
};

export default useCountryConfig;
