import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Routes } from '../../routes/routes';

const useUniversalVariable = (data, userProperties) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      (pathname.includes('NewPurchaseDetails') && !data?.status) ||
      !pathname.includes('checkout')
    ) {
      return;
    }

    const getBreadCrumb = () => {
      if (pathname.includes('collect')) return 'Collect from';
      return Object.values(Routes).filter(
        (route) => route.path === pathname && route,
      )[0].label;
    };

    const sortProducts = (currency) => {
      return data.lineItems.map((lineItem) => {
        const {
          productId,
          sizeLabel,
          imageUrl,
          price,
          wasTotal,
          name,
          slug,
          description,
          color,
          primaryBreadcrumbs,
          skuCode,
          totalDiscount,
          stock,
          quantity,
          total,
        } = lineItem;

        return {
          product: {
            name,
            url: slug,
            currency,
            id: productId,
            in_stock: stock > 0,
            image_url: imageUrl,
            product_division: primaryBreadcrumbs[0],
            ...(color && { color }),
            ...(description && { description }),
            ...(sizeLabel && { size: sizeLabel }),
            unit_price: Number(price.value),
            unit_sale_price: wasTotal
              ? Number(wasTotal.value)
              : Number(price.value),
            category:
              primaryBreadcrumbs &&
              primaryBreadcrumbs[primaryBreadcrumbs.length - 1],
            ...(skuCode && { sku_code: skuCode }),
          },
          quantity,
          subtotal: Number(total.value),
          total_discount: parseFloat(totalDiscount?.value) || 0,
        };
      });
    };

    const uv = window.universal_variable;

    if (uv && data && userProperties) {
      const breadcrumb = getBreadCrumb();

      const {
        id,
        deliveryOption,
        subtotal,
        total,
        discountCodes,
        discountList,
        locale: { currency },
      } = data;

      const discounts =
        discountList?.length > 0
          ? discountList.map((discount) => discount.name)
          : [];
      uv.basket = {
        id,
        currency,
        line_items: sortProducts(currency),
        vouchers: discountCodes,
        total: Number(total?.value),
        subtotal: Number(subtotal?.value),
        ...(deliveryOption && {
          shipping_method: deliveryOption?.serviceLevel,
        }),
        ...(deliveryOption && {
          shipping_cost: Number(deliveryOption?.price.value),
        }),
        promotions: discounts,
      };

      const { totalOrders, userId } = userProperties;

      uv.user = {
        id: userId,
        language: 'en-GB',
        has_transacted: !!(totalOrders && totalOrders > 0),
        returning: !!userId,
      };

      uv.page = {
        type: 'Checkout',
        breadcrumb: [breadcrumb],
      };
    }
  }, [data, pathname, userProperties]);
};

export default useUniversalVariable;
