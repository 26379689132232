export const Routes = {
  deliveryType: {
    path: '/checkout/delivery-type',
    label: 'Delivery method',
    stepperGroupIndex: 0,
    analytics: {
      stepId: 1,
      stepIndex: 2,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'delivery-method',
    },
  },
  homeDelivery: {
    path: '/checkout/home-delivery',
    label: 'Delivery address',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 2,
      stepIndex: 3,
      stepName: 'deliveryaddressselection',
      stepDisplayName: 'delivery-address',
    },
  },
  selectAddress: {
    path: '/checkout/home-delivery/select-address',
    label: 'Delivery address',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 3,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'delivery-address',
    },
  },
  addressForm: {
    path: '/checkout/home-delivery/new-address',
    label: 'Delivery address',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 4,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'delivery-address',
    },
  },
  bfpoForm: {
    path: '/checkout/home-delivery/new-address-bfpo',
    label: 'Delivery address',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 5,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'delivery-address',
    },
  },
  storeSearch: {
    path: '/checkout/collect-in-store',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 6,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'collection-store',
    },
  },
  storeDetails: {
    path: '/checkout/collect-in-store/store/:storeId',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 7,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'collection-store',
    },
  },
  storeConfirm: {
    path: '/checkout/collect-in-store/confirm/:storeId',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 8,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'collection-store',
    },
  },
  storeError: {
    path: '/checkout/collect-in-store/error',
    label: 'Collect from store error',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 7,
      stepIndex: 3,
      stepName: 'deliverytypeselection-error',
      stepDisplayName: 'collection-store-error',
    },
  },
  localShopSearch: {
    path: '/checkout/collect-in-shop',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 9,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'local-shop',
    },
  },
  localShopDetails: {
    path: '/checkout/collect-in-shop/shop/:shopName',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 10,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'local-shop',
    },
  },
  localShopConfirm: {
    path: '/checkout/collect-in-shop/confirm/:shopName',
    label: 'Collect from',
    stepperGroupIndex: 1,
    analytics: {
      stepId: 11,
      stepIndex: 3,
      stepName: 'deliverytypeselection',
      stepDisplayName: 'local-shop',
    },
  },
  deliveryOptions: {
    path: '/checkout/delivery-options',
    label: 'Delivery options',
    stepperGroupIndex: 2,
    analytics: {
      stepId: 12,
      stepIndex: 4,
      stepName: 'deliveryoptionselection',
      stepDisplayName: 'delivery-options',
    },
  },
  reviewAndPay: {
    path: '/checkout/review-and-pay',
    label: 'Review & pay',
    stepperGroupIndex: 3,
    analytics: {
      stepId: 13,
      stepIndex: 5,
      stepName: 'paymentselection',
      stepDisplayName: 'payment',
    },
  },
  orderConfirmation: {
    path: '/checkout/order/NewPurchaseDetails',
    label: 'Order summary',
    analytics: {
      stepId: 14,
      stepIndex: 6,
      stepName: 'purchase',
      stepDisplayName: 'confirmation',
    },
  },
  localCheckout: {
    path: '/local-checkout',
    label: 'Global-E',
    stepperGroupIndex: 6,
    analytics: {
      stepId: 15,
      stepIndex: 6,
      stepName: 'checkout-globale',
      stepDisplayName: 'globale',
    },
  },
  notAvailable: {
    path: '/not-available',
    label: 'Not Available',
    stepperGroupIndex: 0,
  },
};
