import { gql } from '@apollo/client';

import { CART_FIELDS_FRAGMENT, GIFT_CARD_FIELDS_FRAGMENT } from '../fragments';

export const SET_SHIPPING_ADDRESS = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation SetShippingAddress(
    $address: AddressInput!
    $shippingSameAsBilling: Boolean!
    $collectionDetails: CollectionDetailsInput
    $storeDetails: StoreDetailsInput
    $shopDetails: ShopDetailsInput
  ) {
    setShippingAddress(
      args: {
        address: $address
        shippingSameAsBilling: $shippingSameAsBilling
        collectionDetails: $collectionDetails
        storeDetails: $storeDetails
        shopDetails: $shopDetails
      }
    ) {
      ...AllCartFields
    }
  }
`;

export const SET_SHIPPING_ADDRESS_BY_ID = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation SetShippingAddressById(
    $addressId: String!
    $shippingSameAsBilling: Boolean!
  ) {
    setShippingAddressById(
      args: {
        addressId: $addressId
        shippingSameAsBilling: $shippingSameAsBilling
      }
    ) {
      ...AllCartFields
    }
  }
`;

export const SET_BILLING_ADDRESS_BY_ID = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation SetBillingAddressById(
    $addressId: String!
    $cartLocation: CartLocation!
  ) {
    cartLocation @client @export(as: "cartLocation")
    setBillingAddressById(
      args: { addressId: $addressId, cartLocation: $cartLocation }
    ) {
      ...AllCartFields
    }
  }
`;

export const INITIALISE_PAYMENT = gql`
  mutation InitialisePayment($ravelinFingerprint: String) {
    initialisePayment(args: { ravelinFingerprint: $ravelinFingerprint }) {
      id
      secret
      paymentMethods
    }
  }
`;
export const INITIALISE_PAYMENT_V2 = gql`
  mutation InitialisePaymentV2($ravelinFingerprint: String) {
    initialisePaymentV2(args: { ravelinFingerprint: $ravelinFingerprint }) {
      id
      secret
      paymentMethods
      customerSessionClientSecret
    }
  }
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder($paymentId: String!) {
    submitOrder(args: { paymentId: $paymentId }) {
      id
      status
    }
  }
`;

export const SAVE_ADDRESS = gql`
  mutation SaveAddress($args: SaveAddressInput!) {
    saveAddress(args: $args) {
      id
      addressType
      title
      firstName
      lastName
      addressLine1
      addressLine2
      addressLine3
      city
      postalCode
      country
      phone
      deliveryInstruction
      isBfpo
      isDefaultAddress
      isShippable
      isBillable
    }
  }
`;

export const ADD_GIFT_CARD = gql`
  ${GIFT_CARD_FIELDS_FRAGMENT}
  mutation AddGiftcard($number: String!, $pin: String!) {
    addGiftcard(args: { number: $number, pin: $pin }) {
      ...GiftCardFields
    }
  }
`;

export const REMOVE_GIFT_CARD = gql`
  ${GIFT_CARD_FIELDS_FRAGMENT}
  mutation RemoveGiftcard($id: String!) {
    removeGiftcard(args: { id: $id }) {
      ...GiftCardFields
    }
  }
`;

export const AUTHENTICATED_TOKEN = gql`
  mutation AuthenticatedToken($authTokenInput: AuthTokenInput!) {
    authenticatedToken(authTokenInput: $authTokenInput) {
      token_type
      expires_in
      access_token
    }
  }
`;

export const ADD_DISCOUNT_CODE = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation AddDiscountCode($code: String!, $cartLocation: CartLocation!) {
    cartLocation @client @export(as: "cartLocation")
    addDiscountCode(args: { code: $code, cartLocation: $cartLocation }) {
      ...AllCartFields
    }
  }
`;

export const LOGIN = gql`
  mutation Login {
    login
  }
`;

export const INITIALISE_GLOBALE = gql`
  mutation InitialiseGlobale {
    initialiseGlobale {
      id
      clientSettings {
        AdScaleClientSDKURL {
          Value
        }
        AllowClientTracking {
          Value
        }
        AmazonUICulture {
          Value
        }
        CDNEnabled {
          Value
        }
        ChargeMerchantForPrepaidRMAOfReplacement {
          Value
        }
        CheckoutCDNURL {
          Value
        }
        CheckoutContainerSuffix {
          Value
        }
        EnableReplaceUnsupportedCharactersInCheckout {
          Value
        }
        FullClientTracking {
          Value
        }
        GTM_ID {
          Value
        }
        IsMonitoringMerchant {
          Value
        }
        IsV2Checkout {
          Value
        }
        MerchantIdHashed {
          Value
        }
        RefundRMAReplacementShippingTypes {
          Value
        }
        SetGEInCheckoutContainer {
          Value
        }
        ShowFreeShippingBanner {
          Value
        }
        TabletAsMobile {
          Value
        }
      }
    }
  }
`;
export const CONFIRM_GLOBALE_ORDER = gql`
  mutation ConfirmGlobaleCart {
    confirmGlobaleCart {
      id
    }
  }
`;

export const SET_DELIVERY_OPTION = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation SetDeliveryOption($key: String!, $timeSlot: TimeSlotInput) {
    setDeliveryOption(args: { key: $key, timeSlot: $timeSlot }) {
      ...AllCartFields
    }
  }
`;

export const PRE_AUTHORISATION = gql`
  mutation PreAuthorisation($args: PreAuthorisationInput!) {
    preAuthorisation(args: $args)
  }
`;

export const ADD_TO_CART = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation AddToCart(
    $productId: String!
    $sizeKey: String!
    $quantity: Int!
    $cartLocation: CartLocation!
  ) {
    cartLocation @client @export(as: "cartLocation")
    addToCart(
      args: {
        productId: $productId
        sizeKey: $sizeKey
        quantity: $quantity
        cartLocation: $cartLocation
      }
    ) {
      __typename
      ...AllCartFields
    }
  }
`;

export const REMOVE_ITEM = gql`
  ${CART_FIELDS_FRAGMENT}
  mutation RemoveItem($id: String!, $cartLocation: CartLocation!) {
    cartLocation @client @export(as: "cartLocation")
    removeItem(args: { id: $id, cartLocation: $cartLocation }) {
      __typename
      ...AllCartFields
    }
  }
`;
