import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import style from './UnderlinedCTA.scss';

const cx = classNames.bind(style);

const UnderlinedCTA = ({ as, children, variant, ...rest }) => {
  const Component = as || Link;

  return (
    <Component className={cx('underlined-cta', variant)} {...rest}>
      {children}
    </Component>
  );
};

UnderlinedCTA.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  as: PropTypes.string,
};

UnderlinedCTA.defaultProps = {
  variant: '',
  as: '',
};

export default UnderlinedCTA;
