import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

import { journeyHasError as journeyHasErrorVar } from '../../lib/reactives';
import { TRANSLATIONS as text } from '../../translations';
import FlashMessage from '../FlashMessage';
import styles from './Container.scss';

const {
  flashMessages: { journeyError },
} = text;
export function Container({ children, ...rest }) {
  const journeyHasError = useReactiveVar(journeyHasErrorVar);
  return (
    <div className={styles.container} {...rest} data-qa="container">
      {journeyHasError && <FlashMessage message={journeyError} />}
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
