import { useQuery } from '@apollo/client';

import { GET_CONVERT_CART } from '../../lib/queries';
import { redirectToGlobalE } from '../../lib/reactives';

export const useUSRedirect = ({ cart }) => {
  const isRedirected = redirectToGlobalE();

  const { data, loading } = useQuery(GET_CONVERT_CART, {
    variables: {
      args: {
        locale: {
          country: 'US',
          currency: 'USD',
        },
      },
    },
    skip: !isRedirected,
  });

  return {
    newCart: !isRedirected ? cart : data?.cart,
    loading,
  };
};
