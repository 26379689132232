import {
  getCurrentActiveFeatureNames,
  isActiveFeatureName,
} from '@paralleldrive/feature-toggles';

import initialFeatures from '../../lib/features.json';

const activeFeaturesNames = getCurrentActiveFeatureNames({
  initialFeatures,
});

export const isGlobaleRedirectActive = () => {
  const isGlobaleRedirectEnabled = isActiveFeatureName(
    'GlobaleRedirect',
    activeFeaturesNames,
  );

  const isGlobaleRedirectOverrideEnabled = isActiveFeatureName(
    'GlobaleRedirectOverride',
    activeFeaturesNames,
  );

  const xCountry = document.body.getAttribute('x-country');
  if (isGlobaleRedirectOverrideEnabled) return true;

  if (
    !isGlobaleRedirectEnabled ||
    !xCountry ||
    xCountry.toLocaleLowerCase() !== 'us'
  )
    return false;

  return true;
};
