import React from 'react';
import { Route } from 'react-router-dom';

import { OrderConfirmationPage } from '../../pages';
import { Routes } from '../../routes/routes';
import RoutesWrapper from '../../routes/RoutesWrapper';

const OrderConfirmationLayout = () => (
  <RoutesWrapper>
    <Route
      path={Routes.orderConfirmation.path}
      element={<OrderConfirmationPage />}
    />
  </RoutesWrapper>
);

export default OrderConfirmationLayout;
