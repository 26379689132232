import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { TRANSLATIONS as text } from '../../translations';
import ProductImage from './ProductImage';
import style from './ProductRow.scss';

const cx = classNames.bind(style);
const { productRow } = text;

const UnlimitedDelivery = ({ netPrice, productImage, imageBaseURL, name }) => (
  <li className={cx('mybag__product')} data-qa="product">
    <ProductImage
      name={name}
      productImage={productImage}
      baseUrl={imageBaseURL}
    />
    <div className={cx('mybag__product--details')}>
      <h3
        className={cx('mybag__product--unlimited-delivery')}
        data-qa="product-name"
      >
        {name}
      </h3>
      <span className={cx('mybag__product--info')} data-qa="product-info">
        {productRow.unlimitedDelivery}
      </span>
      <span className={cx('mybag__product--price')} data-qa="product-price">
        {netPrice}
      </span>
    </div>
  </li>
);

UnlimitedDelivery.propTypes = {
  netPrice: PropTypes.string.isRequired,
  productImage: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  imageBaseURL: PropTypes.string,
};
UnlimitedDelivery.defaultProps = {
  productImage: null,
  imageBaseURL: 'images.riverisland.com',
};

export default UnlimitedDelivery;
