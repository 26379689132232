import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import LoadingOverlay from '@ri-digital/web-component-library/src/molecule/LoadingOverlay';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import initialFeatures from '../../lib/features.json';
import { NotAvailablePage } from '../../pages';
import { Routes } from '../routes';

const RoutesWrapper = ({ children }) => {
  const { search } = window.location;

  const activeFeatures = getCurrentActiveFeatureNames({
    initialFeatures,
    req: { query: search },
  });
  return (
    <Suspense fallback={<LoadingOverlay loading variant="inOverlay" />}>
      <FeatureToggles features={activeFeatures}>
        <RouterRoutes>
          <Route
            path={Routes.notAvailable.path}
            element={<NotAvailablePage />}
          />
          <Route
            exact
            path={Routes.localCheckout.path}
            element={<Navigate replace to={Routes.deliveryType.path} />}
          />
          <Route
            path="/"
            element={<Navigate replace to={Routes.deliveryType.path} />}
          />
          <Route
            path="*"
            element={<Navigate replace to={Routes.deliveryType.path} />}
          />
          {children}
        </RouterRoutes>
      </FeatureToggles>
    </Suspense>
  );
};

RoutesWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default RoutesWrapper;
