import classNames from 'classnames/bind';
import React from 'react';

import style from './MyBag.scss';
import MyBagContentLoader from './MyBagContentLoader';

const cx = classNames.bind(style);

export function MyBagSkeleton() {
  return (
    <>
      <div className={cx('loader-wrapper-desktop')}>
        <div className={cx('loader-wrapper-desktop__bag')}>
          <MyBagContentLoader />
        </div>
      </div>
      <div className={cx('loader-wrapper-tablet')}>
        <div className={cx('loader-wrapper-tablet__bag')}>
          <MyBagContentLoader isTablet />
        </div>
      </div>
    </>
  );
}
