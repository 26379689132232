import * as yup from 'yup';

import { TRANSLATIONS as text } from '../../translations';

const { promoCodeText } = text;
const { empty } = promoCodeText;

export default yup.object().shape({
  promoCode: yup.string().required(empty),
});
