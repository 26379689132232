import addToDataLayer from '../../../helpers/addToDataLayer';

const newsLetterSignUp = (name) => {
  const data = {
    source: name,
  };

  addToDataLayer('newsLetterSignUp', data);
};

export default newsLetterSignUp;
