import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { TRANSLATIONS as text } from '../../translations';
import style from './OpeningTimes.scss';

const cx = classNames.bind(style);

const OpeningTimes = ({ openingTimesHtml, openingTimes }) => {
  const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] =
    openingTimes;

  // eslint-disable-next-line react/no-danger
  return openingTimesHtml ? (
    <div
      data-qa="opening-times"
      className={cx('opening-times')}
      dangerouslySetInnerHTML={{ __html: openingTimesHtml }}
    />
  ) : (
    <>
      <div data-qa="opening-times" className={cx('opening-times')}>
        <p>
          <br />
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>{text.monday}:</strong>
              </td>
              <td>{monday}</td>
            </tr>
            <tr>
              <td>
                <strong>{text.tuesday}:</strong>
              </td>
              <td>{tuesday}</td>
            </tr>
            <tr>
              <td>
                <strong>{text.wednesday}:</strong>
              </td>
              <td>{wednesday}</td>
            </tr>
            <tr>
              <td>
                <strong>{text.thursday}:</strong>
              </td>
              <td>{thursday}</td>
            </tr>
            <tr>
              <td>
                <strong>{text.friday}:</strong>
              </td>
              <td>{friday}</td>
            </tr>
            <tr>
              <td>
                <strong>{text.saturday}:</strong>
              </td>
              <td>
                {saturday}
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <strong>{text.sunday}:</strong>
              </td>
              <td>{sunday}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <strong>{text.storeDetails.pleaseNote}: </strong>
      <span>{text.storeDetails.confirmTimes}</span>
    </>
  );
};

OpeningTimes.defaultProps = {
  openingTimesHtml: '',
  openingTimes: [],
};

OpeningTimes.propTypes = {
  openingTimesHtml: PropTypes.string,
  openingTimes: PropTypes.arrayOf(PropTypes.string),
};

export default OpeningTimes;
