import addToDataLayer from '../../../helpers/addToDataLayer';

const paymentError = (message) => {
  addToDataLayer('general_errors', {
    error: {
      description: message,
      type: 'payment',
    },
  });
};

export default paymentError;
