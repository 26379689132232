import addDiscountCode from './events/addDiscountCode';
import addPaymentInfo from './events/addPaymentInfo';
import checkout from './events/checkout';
import deliveryLocation from './events/deliveryLocation';
import deliveryMethod from './events/deliveryMethod';
import deliveryOption from './events/deliveryOption';
import pageMetaData from './events/pageMetaData';
import paymentError from './events/paymentError';
import paymentMethod from './events/paymentMethod';
import purchase from './events/purchase';
import purchaseGlobale from './events/purchaseGlobale';
import shippingInfo from './events/shippingInfo';
import unlimitedDeliveryCartInteraction from './events/unlimitedDeliveryCartInteraction';
import formatBrands from './helpers/formatBrands';

export default {
  addDiscountCode,
  deliveryMethod,
  deliveryOption,
  paymentMethod,
  addPaymentInfo,
  pageMetaData,
  checkout,
  purchase,
  purchaseGlobale,
  shippingInfo,
  unlimitedDeliveryCartInteraction,
  formatBrands,
  paymentError,
  deliveryLocation,
};
