import './styles/global.scss';
import '@ri-digital/web-data-layer';

import LoadingOverlay from '@ri-digital/web-component-library/src/molecule/LoadingOverlay';
import Footer from '@ri-digital/web-component-library/src/organism/Footer';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import getCookie from '../analytics/helpers/getCookie/getCookie';
import style from './App.scss';
import Header from './components/Header';
import extractDomain from './helpers/extractDomain';
import useSDKSync from './hooks/useSDKSync';
import MainLayout from './layouts/MainLayout';
import OrderConfirmationLayout from './layouts/OrderConfirmationLayout';
import ReviewAndPayRedirectLayout from './layouts/ReviewAndPayRedirectLayout';
import { appOs, isWebview } from './lib/reactives';
import { Routes } from './routes/routes';

const cx = classNames.bind(style);

const stripeRedirectStatusesForProcessingOrder = ['pending', 'succeeded'];

const App = () => {
  const { pathname } = window.location;
  const [searchParams] = useSearchParams();
  const redirectStatus = searchParams.get('redirect_status');
  const isOrderPage = searchParams.get('trackingNumber');
  const isAlternativePaymetRedirect =
    stripeRedirectStatusesForProcessingOrder.includes(redirectStatus);

  useSDKSync();

  const [showWebviewUI, setShowWebViewUI] = useState(false);

  useEffect(() => {
    if (searchParams.get('webview')) {
      setShowWebViewUI(true);
      isWebview(true);
      appOs(searchParams.get('os'));

      if (Routes.localCheckout.path === pathname) {
        document.cookie = `isWebView=true;domain=${extractDomain()};path=/`;
      }
    }
  }, [searchParams, pathname]);

  const isGlobalEWebview =
    Routes.localCheckout.path === pathname && getCookie('isWebView');

  const appContent = () => {
    if (isAlternativePaymetRedirect && !isOrderPage) {
      return <ReviewAndPayRedirectLayout />;
    }
    if (!isAlternativePaymetRedirect && !isOrderPage) {
      return <MainLayout />;
    }
    if (!isAlternativePaymetRedirect && isOrderPage) {
      return <OrderConfirmationLayout />;
    }
    return <LoadingOverlay loading variant="inOverlay" />;
  };

  return (
    <div className={cx('app')}>
      {showWebviewUI || isGlobalEWebview ? null : <Header />}
      {appContent()}
      {showWebviewUI || isGlobalEWebview ? null : <Footer />}
    </div>
  );
};

export default App;
