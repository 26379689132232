import { useMutation } from '@apollo/client';

import * as mutations from '../../lib/mutations';
import { TRANSLATIONS as text } from '../../translations';
import errorMapper from '../utils/errorMapper';
import { replaceCartCache } from '../utils/replaceCartCache';

export const errorMessages = {
  InvalidPostCode: text.errors.InvalidPostCode,
};

export const useSetShippingAddress = (options) => {
  const [setShippingAddress, { loading, called, error, data }] = useMutation(
    mutations.SET_SHIPPING_ADDRESS,
    {
      update: replaceCartCache('setShippingAddress'),
      ...options,
    },
  );

  const mutationFn = (mutation) => async (variables) => {
    return mutation({ variables });
  };

  return {
    called,
    loading,
    data,
    error: errorMapper(error, errorMessages),
    setShippingAddress: mutationFn(setShippingAddress),
  };
};
