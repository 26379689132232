import addToDataLayer from '../../../helpers/addToDataLayer';

const deliveryMethod = (name) => {
  const data = {
    deliveryMethod: {
      name: `delivery: ${name}`,
    },
  };

  addToDataLayer('deliveryMethod', data);
};

export default deliveryMethod;
