import { Icon } from '@ri-digital/web-component-library/src/atom/Icon';
import classNames from 'classnames/bind';
import React from 'react';

import { TRANSLATIONS as text } from '../../translations';
import style from './ConfirmOwnership.scss';

const cx = classNames.bind(style);
const ConfirmOwnership = () => {
  return (
    <div className={cx('confirm-ownership')}>
      <h3
        className={cx('confirm-ownership__header')}
        data-qa="confirm-ownership-header"
      >
        {text.confirmOwnership.bringWithYou}
      </h3>
      <div className={cx('confirm-ownership__order-tracking-number')}>
        <Icon name="barcode" />
        <span
          className={cx('confirm-ownership__order-tracking-number-text')}
          data-qa="confirm-ownership-order-tracking-number"
        >
          {text.confirmOwnership.orderTrackingNumber}
        </span>
      </div>
      <div className={cx('confirm-ownership__proof-of-identity')}>
        <Icon name="user" />
        <span
          className={cx('confirm-ownership__proof-of-identity-text')}
          data-qa="confirm-ownership-proof-of-identity"
        >
          {text.confirmOwnership.proofOfIdentity}
        </span>
      </div>
      <h4 className={cx('confirm-ownership__note')}>
        {`${text.confirmOwnership.pleaseNote}: `}
      </h4>
      <p
        className={cx('confirm-ownership__note-details')}
        data-qa="confirm-ownership-collect-by-other"
      >
        {text.confirmOwnership.collectByOther}
      </p>
    </div>
  );
};

export default ConfirmOwnership;
