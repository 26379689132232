import formatBrands from '../../analytics/helpers/formatBrands';
import { deliveryProvider } from '../../lib/reactives';

export const productMapper = (lineItems, type) => {
  return lineItems.map((lineItem) => {
    const {
      brands,
      primaryBreadcrumbs,
      name,
      price,
      productId,
      quantity,
      sizeKey,
      sizeLabel,
      wasPrice,
      skuCode,
      color,
      stock,
    } = lineItem;

    const [division, category, subcategory] = primaryBreadcrumbs;

    const formattedBrands = formatBrands(brands);

    return {
      id: productId,
      skuId: `${productId},${sizeKey}`,
      name,
      quantity,
      brands: formattedBrands,
      affiliation: 'web_store',
      category,
      subcategory,
      division,
      size: sizeLabel,
      skuCode,
      colour: color,
      ...(!type && { stock }),
      isMarkedDown: !!wasPrice,
      price: price.value,
    };
  });
};

export const mapGlobalEOrder = (orderData) => {
  const {
    OrderId,
    details: {
      customerDiscountedShippingPrice,
      customerTotalPrice,
      totalCustomerProductsPrice,
      shippingServiceName,
    },
  } = orderData;

  return {
    id: OrderId,
    deliveryPassOrder: false,
    paymentMethod: 'Global-E Payment',
    shipping: customerDiscountedShippingPrice.toFixed(2).toString(),
    shippingMethod: shippingServiceName,
    revenue: totalCustomerProductsPrice.toFixed(2).toString(),
    total: customerTotalPrice.toFixed(2).toString(),
    coupon: '',
    couponDiscount: '',
    discountList: [],
  };
};

export const isDeliveryPass = (item) =>
  item.productType === 'river-island-shipping-pass';

const formatDiscountList = (discountList) => {
  return discountList.map(({ name, value: { value: amount } }) => {
    return {
      name,
      amount: (amount * -1).toFixed(2),
    };
  });
};

export const mapOrder = (
  orderData,
  currency,
  customerHasActiveSubscription,
) => {
  const hasDeliveryPass =
    orderData.lineItems.filter((lineItem) => isDeliveryPass(lineItem)).length >
      0 || customerHasActiveSubscription;

  const {
    id,
    paymentMethods,
    discountCodes,
    discountList,
    totalDiscountExcludingShipping,
    deliveryOption: {
      serviceLevel,
      price: { value: shippingPrice },
    },
    total: { value: totalPrice },
    subtotal: { value: subtotalPrice },
    createdAt,
    shippingAddress: { addressType },
  } = orderData;

  let purchaseData = {
    id,
    currencyCode: currency,
    deliveryPassOrder: hasDeliveryPass,
    paymentMethod: paymentMethods[0],
    shipping: shippingPrice,
    shippingMethod: serviceLevel,
    revenue: (subtotalPrice - totalDiscountExcludingShipping.value).toFixed(2),
    total: totalPrice,
    timestamp: createdAt,
    deliveryType: addressType,
    deliveryProvider: addressType === 'shop' ? deliveryProvider() : '',
  };

  if (discountList.length > 0) {
    const discountData = {
      coupon: discountCodes[0],
      couponDiscount: totalDiscountExcludingShipping?.value,
      discountList: formatDiscountList(discountList),
    };

    purchaseData = { ...purchaseData, ...discountData };
  }

  return purchaseData;
};
